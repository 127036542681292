export const coursedata = [
  {
    category: "100% Job Guarantee",
    url: "100-percent-job-guarantee",
    categoryIcon: "../assets/categories/job.png",
    courses: [
      {
        url: "ms-cit",
        name: "MS-CIT",
        icon: "../assets/courses/mscit.svg",
        content: `MS-CIT is an Information Technology (IT) literacy course started by MKCL in the year 2001. It is the most popular IT Literacy course in Maharashtra.In 21st Century, most of the new actionable knowledge is being digitally born (often through digital collaborations), digitally stored, digitally presented, digitally distributed, digitally accessed, digitally archived and managed. It only seems natural that it has become an essential part of one’s personal, professional and social life. It has also transformed the way of living in 21st Century. 
        <br><br>
        MS-CIT attempts to propagate it through IT Awareness, Literacy, Functionality and Applicability among the common people with a view to bridge the Digital Divide and the resultant Knowledge Divide and Development Opportunity Divide. This surely makes a positive impact on one’s job-readiness, social behavior and ultimately boosts the self-confidence, enabling him/her to work effectively in the 21st Century workplace.`,
        certification: `
        <ul>
        <li>MS-CIT Completion certificate by MKCL (Maharshtra State Goverment)</li>
        </ul>
        `,
        objective:
          "This course aims to equip participants with essential computer skills and proficiency in Microsoft Office applications, including Word, Excel, PowerPoint, and Access. Participants will gain practical knowledge to enhance productivity in professional settings and prepare for the MS-CIT exam, improving their competitiveness in the job market.",
        syllabus: `<h2><b>This Course Comprises of:</b></h2>
        <ul style="padding-left: 30px;">
            <li>eLearning based self-learning sessions through MKCL eLearning Revolution for All (ERA).</li>
            <li>Hands-on practice sessions.</li>
            <li>Learning facilitation by certified professionals.</li>
            <li>Academic interactions, assessments, and collaboration.</li>
            <li>Reading and understanding a highly illustrated book.</li>
        </ul>
        `,
        topics: `<h2 style="font-weight: bold;">Computer Basics and Web</h2>
        <ul style="padding-left: 30px;">
            <li>Fundamental computer concepts, such as equipment, computer program, and working frameworks</li>
            <li>Fundamental web concepts, such as web browsers, mail, and social media</li>
            <li>Computer security and security</li>
        </ul>
        <br>
        
        <h2 style="font-weight: bold;">Word Preparing</h2>
        <ul style="padding-left: 30px;">
            <li>Making and altering archives in Microsoft Word</li>
            <li>Organizing content and passages</li>
            <li>Embeddings and altering pictures and tables</li>
            <li>Making and overseeing mail blend archives</li>
        </ul>
        <br>
        
        <h2 style="font-weight: bold;">Spreadsheet</h2>
        <ul style="padding-left: 30px;">
            <li>Making and altering spreadsheets in Microsoft Exceed expectations</li>
            <li>Entering and organizing information</li>
            <li>Making equations and capacities</li>
            <li>Making charts and charts</li>
        </ul>
        <br>
        
        <h2 style="font-weight: bold;">Introduction</h2>
        <ul style="padding-left: 30px;">
            <li>Making and altering introductions in Microsoft PowerPoint</li>
            <li>Embeddings and altering content, pictures, and recordings</li>
            <li>Applying moves and activitys</li>
            <li>Conveying introductions</li>
        </ul>
        <br>
        
        <h2 style="font-weight: bold;">Database Administration</h2>
        <ul style="padding-left: 30px;">
            <li>Making and altering databases in Microsoft Get to</li>
            <li>Making and overseeing tables, inquiries, and shapes</li>
            <li>Producing reports</li>
        </ul>
        <br>
        
        <h2 style="font-weight: bold;">Programming</h2>
        <ul style="padding-left: 30px;">
            <li>Essential programming concepts, such as factors, information sorts, circles, and capacities</li>
            <li>Presentation to the C programming dialect</li>
        </ul>
        <br>`,
        price: "6200",
        duration: "100 Hours",
        level: "Begineer",
        popular: true,
      },
      {
        url: "adv-excel",
        name: "Advanced Excel",
        icon: "../assets/courses/excel.svg",
        content:
          "Excel skills are as important as the subject knowledge. Those who know Excel can find a better paying job. An Excel Expert collects, edits, analyses data, creates data bases and reports. The conclusions made by the data expert are helpful for organizations to forecast the business speculations.After completion of the course, you can get a suitable position in any organization as an Accounts Assistant, Finance Analyst, Retail Store Manager and Data Analyst in any organization.",
        certification: `
        <ul>
        <li>KLiC courses are recognised by Yashwantrao Chavan Maharashtra Open University (YCMOU).</li>
        <li>MKCL provides certificate to the KLiC learner after his/her successful course completion.</li>
        <li>Yashwantrao Chavan Maharashtra Open University (YCMOU) provides mark sheet to successfully passed KLiC learners (Jurisdiction: Maharashtra).</li>
        </ul>
        `,
        objective:
          "Organizing the data professionally, Using Metadata and Big Data, Internationalization for Excel Workbooks, data segregation using custom formats and layouts, Dashboard creation using Power Query and Power Pivot table, Using Power Map, Connecting Excel workbooks to external data, preparing a checklist for data collection using Canva, Data collection for social media marketing",
        syllabus: `
        The syllabus covers the following skills: 
        <ol style="padding-left:30px">
          <li>Apply custom formats and layouts</li>
          <li>Create advanced formulas</li>
          <li>Use of Scenario</li>
          <li>Pivot tables and charts</li>
          <li>Manage and share workbooks</li>
        </ol>
      `,
        topics: `<h2 style="font-weight: bold;">Basics of Data Management</h2>
        <ul style="padding-left: 30px;">
            <li>Introduction to Data Management</li>
            <li>How do professionals organize their data?</li>
            <li>Meta Data and Big Data</li>
            <li>Freelance Data Entry Jobs</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Introduction to Computer Typing and Data Entry</h2>
        <ul style="padding-left: 30px;">
            <li>Introduction to Typing</li>
            <li>Keyboarding</li>
            <li>Ergonomic Typing</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Introduction to various Typing Methods</h2>
        <ul style="padding-left: 30px;">
            <li>Using different types of keys and symbols in typing</li>
            <li>Google Docs – Voice Typing</li>
            <li>Lipikaar App</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Introduction to Data Analysis</h2>
        <ul style="padding-left: 30px;">
            <li>Text Formulas</li>
            <li>Text Manipulation Functions</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Apply Custom Formats and Layouts</h2>
        <ul style="padding-left: 30px;">
            <li>Apply Custom Data Formats</li>
            <li>Use Advanced Fill Options</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Create Advanced Charts</h2>
        <ul style="padding-left: 30px;">
            <li>Basic Excel Charts</li>
            <li>Use Area, Scatter & Stock Charts</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Pivot tables and charts</h2>
        <ul style="padding-left: 30px;">
            <li>Pivot Tables</li>
            <li>Use Pivot Charts</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Data Visualization and Data Analysis</h2>
        <ul style="padding-left: 30px;">
            <li>Use of Macros</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Use Scenarios</h2>
        <ul style="padding-left: 30px;">
            <li>Use What-If Analysis Tools</li>
            <li>Create Scenarios</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Manage and Share Workbooks</h2>
        <ul style="padding-left: 30px;">
            <li>Manage Workbook Versions</li>
            <li>Copy Styles between Workbooks</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Typical Data Entry Projects</h2>
        <ul style="padding-left: 30px;">
            <li>File Conversion from PDF to Word</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Create Advanced Formulas</h2>
        <ul style="padding-left: 30px;">
            <li>Functions</li>
            <li>Apply functions in formulas</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Free and Open-Source Tools for Data Management</h2>
        <ul style="padding-left: 30px;">
            <li>Working with Google Sheets</li>
            <li>Using OpenOffice.org Writer</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Different Data Entry Methods and Data Cleaning</h2>
        <ul style="padding-left: 30px;">
            <li>Using Komprehend</li>
            <li>Excel Shortcuts</li>
        </ul>
        <br/>
        <h2 style="font-weight: bold;">Introduction to DBMS</h2>
        <ul style="padding-left: 30px;">
            <li>What is a DBMS and its purpose?</li>
        </ul>`,
        price: "5500",
        duration: "120 Hours",
        level: "Intermediate",
        popular: true,
      },
      {
        url: "adv-tally",
        name: "Advanced Tally",
        icon: "../assets/courses/tally.svg",
        content: `This course offers in-depth knowledge of Tally ERP9. You will get holistic learning experience. Covers various types of account transactions in diverse business and industries. With Tally ERP, you can maintain records of multiple companies. With the dynamic software, you can perform all accounting jobs including taxation, payroll accounting etc. You can go paperless with Tally ERP 9.`,
        certification: `
        <ul>
        <li>KLiC courses are recognised by Yashwantrao Chavan Maharashtra Open University (YCMOU).</li>
        <li>MKCL provides certificate to the KLiC learner after his/her successful course completion.</li>
        <li>Yashwantrao Chavan Maharashtra Open University (YCMOU) provides mark sheet to successfully passed KLiC learners (Jurisdiction: Maharashtra).</li>
        </ul>
        `,
        objective: `Balance Sheet, Credit sales Entry, Create Sales Excise Voucher, Ledger Creation, Create Stock Go-down, Checking Order, Create Stock Item, Voucher Creation, Cost Centre Creation`,
        syllabus: `The syllabus covers the following skills: 
        <ul style="padding-left:30px">
        <li>Accounting voucher entries including voucher configuration and setup</li>
        <li>Inventory setup and advanced inventory configuration</li>
        <li>Concepts and applications of VAT, Service Tax, TDS, TCS, and Excise</li>
        <li>Advanced accounting transaction recording, inclusive of Industrial Accounting, Indirect Taxation, and various statutory forms for tax filing requirements</li>
        <li>Payroll management and transactions</li>
        <li>Report generation and Financial Analysis</li>
        <li>Application of all the above aspects in Tally</li>
    </ul>`,
        topics: `<h2 style="font-weight: bold;">Advanced Accounting</h2>
        <ul style="padding-left: 30px;">
            <li>Cost centers and cost categories
                <ul style="padding-left: 30px;">
                    <li>Creating different ledgers</li>
                    <li>Creating different cost categories and Centres</li>
                    <li>Making different types of voucher entries</li>
                    <li>Viewing different cost center reports</li>
                </ul>
            </li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Interest Calculations</h2>
        <ul style="padding-left: 30px;">
            <li>Activating Interest Calculation</li>
            <li>Interest on Outstanding Balances</li>
            <li>Ledger Interest</li>
            <li>Interest on Invoices, Interest Parameters</li>
            <li>Debit Note Interest</li>
            <li>Calculating Interest in Advanced Mode</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Budgets and Controls</h2>
        <ul style="padding-left: 30px;">
            <li>Budgets Introduction, Activation and Types</li>
            <li>Closing Balance Budget</li>
            <li>Ledger Budget Report</li>
            <li>Cost Centre Budget & Report</li>
            <li>Basic Knowledge of Standard Costing</li>
            <li>Ratio Analysis, Cash Flow and Funds Flow</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Job Costing</h2>
        <ul style="padding-left: 30px;">
            <li>Features of Job Costing</li>
            <li>Activation</li>
            <li>Recording job costing related transaction</li>
            <li>Reports</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Job Work Order</h2>
        <ul style="padding-left: 30px;">
            <li>Activation</li>
            <li>Voucher Type setup for job work out/in</li>
            <li>Job order processing</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Advanced Payroll</h2>
        <ul style="padding-left: 30px;">
            <li>Payroll & Statutory Details</li>
            <li>Income Tax</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">GSTR 1, 2, 3B</h2>
        <ul style="padding-left: 30px;">
            <li>Returns summary</li>
            <li>Computation details</li>
            <li>Summary of exceptions</li>
            <li>Table wise GSTR 1, 2, 3B</li>
            <li>Status reconciliation</li>
            <li>HSN summary</li>
            <li>Exporting GSTR 1, 2, 3B</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Access Controls & Security management</h2>
        <ul style="padding-left: 30px;">
            <li>Enabling Security Control</li>
            <li>Defining Security Level</li>
            <li>Create Users</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Data Synchronization</h2>
        <ul style="padding-left: 30px;">
            <li>Reports, Types and Trial Balances</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">SMS</h2>
        <ul style="padding-left: 30px;">
            <li>Introduction</li>
            <li>Configuration</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Control Centre</h2>
        <ul style="padding-left: 30px;">
            <li>Control centre</li>
            <li>Support centre</li>
        </ul>`,
        price: "6500",
        duration: "120 Hours",
        level: "Intermediate",
        popular: true,
      },
    ],
  },
  {
    category: "Accounting",
    url: "accounting",
    categoryIcon: "../assets/categories/accounting.png",
    courses: [
      {
        url: "klic-adv-excel",
        name: "KLiC Advanced Excel",
        icon: "../assets/courses/klic_excel.svg",
        content:
          "Excel skills are as important as the subject knowledge. Those who know Excel can find a better paying job. An Excel Expert collects, edits, analyses data, creates data bases and reports. The conclusions made by the data expert are helpful for organizations to forecast the business speculations.After completion of the course, you can get a suitable position in any organization as an Accounts Assistant, Finance Analyst, Retail Store Manager and Data Analyst in any organization.",
        certification: `
        <ul>
        <li>KLiC courses are recognised by Yashwantrao Chavan Maharashtra Open University (YCMOU).</li>
        <li>MKCL provides certificate to the KLiC learner after his/her successful course completion.</li>
        <li>Yashwantrao Chavan Maharashtra Open University (YCMOU) provides mark sheet to successfully passed KLiC learners (Jurisdiction: Maharashtra).</li>
        </ul>
        `,
        objective:
          "Organizing the data professionally, Using Metadata and Big Data, Internationalization for Excel Workbooks, data segregation using custom formats and layouts, Dashboard creation using Power Query and Power Pivot table, Using Power Map, Connecting Excel workbooks to external data, preparing a checklist for data collection using Canva, Data collection for social media marketing",
        syllabus: `
        The syllabus covers the following skills: 
        <ol style="padding-left:30px">
          <li>Apply custom formats and layouts</li>
          <li>Create advanced formulas</li>
          <li>Use of Scenario</li>
          <li>Pivot tables and charts</li>
          <li>Manage and share workbooks</li>
        </ol>
      `,
        topics: `<h2 style="font-weight: bold;">Basics of Data Management</h2>
      <ul style="padding-left: 30px;">
          <li>Introduction to Data Management</li>
          <li>How do professionals organize their data?</li>
          <li>Meta Data and Big Data</li>
          <li>Freelance Data Entry Jobs</li>
      </ul>
      <br/>
      
      <h2 style="font-weight: bold;">Introduction to Computer Typing and Data Entry</h2>
      <ul style="padding-left: 30px;">
          <li>Introduction to Typing</li>
          <li>Keyboarding</li>
          <li>Ergonomic Typing</li>
      </ul>
      <br/>
      
      <h2 style="font-weight: bold;">Introduction to various Typing Methods</h2>
      <ul style="padding-left: 30px;">
          <li>Using different types of keys and symbols in typing</li>
          <li>Google Docs – Voice Typing</li>
          <li>Lipikaar App</li>
      </ul>
      <br/>
      
      <h2 style="font-weight: bold;">Introduction to Data Analysis</h2>
      <ul style="padding-left: 30px;">
          <li>Text Formulas</li>
          <li>Text Manipulation Functions</li>
      </ul>
      <br/>
      
      <h2 style="font-weight: bold;">Apply Custom Formats and Layouts</h2>
      <ul style="padding-left: 30px;">
          <li>Apply Custom Data Formats</li>
          <li>Use Advanced Fill Options</li>
      </ul>
      <br/>
      
      <h2 style="font-weight: bold;">Create Advanced Charts</h2>
      <ul style="padding-left: 30px;">
          <li>Basic Excel Charts</li>
          <li>Use Area, Scatter & Stock Charts</li>
      </ul>
      <br/>
      
      <h2 style="font-weight: bold;">Pivot tables and charts</h2>
      <ul style="padding-left: 30px;">
          <li>Pivot Tables</li>
          <li>Use Pivot Charts</li>
      </ul>
      <br/>
      
      <h2 style="font-weight: bold;">Data Visualization and Data Analysis</h2>
      <ul style="padding-left: 30px;">
          <li>Use of Macros</li>
      </ul>
      <br/>
      
      <h2 style="font-weight: bold;">Use Scenarios</h2>
      <ul style="padding-left: 30px;">
          <li>Use What-If Analysis Tools</li>
          <li>Create Scenarios</li>
      </ul>
      <br/>
      
      <h2 style="font-weight: bold;">Manage and Share Workbooks</h2>
      <ul style="padding-left: 30px;">
          <li>Manage Workbook Versions</li>
          <li>Copy Styles between Workbooks</li>
      </ul>
      <br/>
      
      <h2 style="font-weight: bold;">Typical Data Entry Projects</h2>
      <ul style="padding-left: 30px;">
          <li>File Conversion from PDF to Word</li>
      </ul>
      <br/>
      
      <h2 style="font-weight: bold;">Create Advanced Formulas</h2>
      <ul style="padding-left: 30px;">
          <li>Functions</li>
          <li>Apply functions in formulas</li>
      </ul>
      <br/>
      
      <h2 style="font-weight: bold;">Free and Open-Source Tools for Data Management</h2>
      <ul style="padding-left: 30px;">
          <li>Working with Google Sheets</li>
          <li>Using OpenOffice.org Writer</li>
      </ul>
      <br/>
      
      <h2 style="font-weight: bold;">Different Data Entry Methods and Data Cleaning</h2>
      <ul style="padding-left: 30px;">
          <li>Using Komprehend</li>
          <li>Excel Shortcuts</li>
      </ul>
      <br/>
      <h2 style="font-weight: bold;">Introduction to DBMS</h2>
      <ul style="padding-left: 30px;">
          <li>What is a DBMS and its purpose?</li>
      </ul>`,
        price: "6200",
        duration: "120 Hours",
        level: "Intermediate",
      },
      {
        url: "klic-adv-tally-pro",
        name: "KLiC Advanced Tally Pro",
        icon: "../assets/courses/klic_tally.svg",
        content: `This course offers in-depth knowledge of Tally ERP9. You will get holistic learning experience. Covers various types of account transactions in diverse business and industries. With Tally ERP, you can maintain records of multiple companies. With the dynamic software, you can perform all accounting jobs including taxation, payroll accounting etc. You can go paperless with Tally ERP 9.`,
        certification: `
        <ul>
        <li>KLiC courses are recognised by Yashwantrao Chavan Maharashtra Open University (YCMOU).</li>
        <li>MKCL provides certificate to the KLiC learner after his/her successful course completion.</li>
        <li>Yashwantrao Chavan Maharashtra Open University (YCMOU) provides mark sheet to successfully passed KLiC learners (Jurisdiction: Maharashtra).</li>
        </ul>
        `,
        objective: `Balance Sheet, Credit sales Entry, Create Sales Excise Voucher, Ledger Creation, Create Stock Go-down, Checking Order, Create Stock Item, Voucher Creation, Cost Centre Creation`,
        syllabus: `The syllabus covers the following skills: 
        <ul style="padding-left:30px">
        <li>Accounting voucher entries including voucher configuration and setup</li>
        <li>Inventory setup and advanced inventory configuration</li>
        <li>Concepts and applications of VAT, Service Tax, TDS, TCS, and Excise</li>
        <li>Advanced accounting transaction recording, inclusive of Industrial Accounting, Indirect Taxation, and various statutory forms for tax filing requirements</li>
        <li>Payroll management and transactions</li>
        <li>Report generation and Financial Analysis</li>
        <li>Application of all the above aspects in Tally</li>
    </ul>`,
        topics: `<h2 style="font-weight: bold;">Advanced Accounting</h2>
        <ul style="padding-left: 30px;">
            <li>Cost centers and cost categories
                <ul style="padding-left: 30px;">
                    <li>Creating different ledgers</li>
                    <li>Creating different cost categories and Centres</li>
                    <li>Making different types of voucher entries</li>
                    <li>Viewing different cost center reports</li>
                </ul>
            </li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Interest Calculations</h2>
        <ul style="padding-left: 30px;">
            <li>Activating Interest Calculation</li>
            <li>Interest on Outstanding Balances</li>
            <li>Ledger Interest</li>
            <li>Interest on Invoices, Interest Parameters</li>
            <li>Debit Note Interest</li>
            <li>Calculating Interest in Advanced Mode</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Budgets and Controls</h2>
        <ul style="padding-left: 30px;">
            <li>Budgets Introduction, Activation and Types</li>
            <li>Closing Balance Budget</li>
            <li>Ledger Budget Report</li>
            <li>Cost Centre Budget & Report</li>
            <li>Basic Knowledge of Standard Costing</li>
            <li>Ratio Analysis, Cash Flow and Funds Flow</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Job Costing</h2>
        <ul style="padding-left: 30px;">
            <li>Features of Job Costing</li>
            <li>Activation</li>
            <li>Recording job costing related transaction</li>
            <li>Reports</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Job Work Order</h2>
        <ul style="padding-left: 30px;">
            <li>Activation</li>
            <li>Voucher Type setup for job work out/in</li>
            <li>Job order processing</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Advanced Payroll</h2>
        <ul style="padding-left: 30px;">
            <li>Payroll & Statutory Details</li>
            <li>Income Tax</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">GSTR 1, 2, 3B</h2>
        <ul style="padding-left: 30px;">
            <li>Returns summary</li>
            <li>Computation details</li>
            <li>Summary of exceptions</li>
            <li>Table wise GSTR 1, 2, 3B</li>
            <li>Status reconciliation</li>
            <li>HSN summary</li>
            <li>Exporting GSTR 1, 2, 3B</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Access Controls & Security management</h2>
        <ul style="padding-left: 30px;">
            <li>Enabling Security Control</li>
            <li>Defining Security Level</li>
            <li>Create Users</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Data Synchronization</h2>
        <ul style="padding-left: 30px;">
            <li>Reports, Types and Trial Balances</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">SMS</h2>
        <ul style="padding-left: 30px;">
            <li>Introduction</li>
            <li>Configuration</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Control Centre</h2>
        <ul style="padding-left: 30px;">
            <li>Control centre</li>
            <li>Support centre</li>
        </ul>`,
        price: "6200",
        duration: "120 Hours",
        level: "Expert",
      },
      {
        url: "klic-adv-tally-prime",
        name: "KLiC Tally Prime with GST",
        icon: "../assets/courses/klic_tally_gst.svg",
        content: `<h2 style="margin-top: 0;">We are launching a new course with Tally Prime version in comparison to the previous course with Tally version 9 for several important reasons:</h2>
        </br>
        <ol style="padding-left: 20px;">
            <li>
                <strong>Stay Relevant:</strong> Tally Prime represents the latest iteration of Tally's software, incorporating significant enhancements, improvements, and modernization. It is essential for our learners to be up to date with the latest software versions to remain competitive in the field.
            </li>
            </br>

            <li>
                <strong>Enhanced Features:</strong> Tally Prime introduces new features and functionalities that are not available in Tally version 9. These enhancements can streamline accounting processes, improve efficiency, and offer a more comprehensive learning experience.
            </li>
            </br>
            
            <li>
                <strong>Adaptation to Industry Standards:</strong> Many businesses and organizations have upgraded to Tally Prime, making it the industry standard. By offering a course on Tally Prime, we ensure that our learners are prepared to meet the demands of the job market and work effectively in real-world scenarios.
            </li>
            </br>
            
            <li>
                <strong>Improved User Experience:</strong> Tally Prime offers a more user-friendly and intuitive interface compared to Tally version 9. Teaching with the latest software ensures that our learners have a smoother and more enjoyable learning experience.
            </li>
            </br>
            <li>
                <strong>Future-Proofing:</strong> By focusing on Tally Prime, we future-proof our course content. This means that our learners will acquire skills that are relevant not only in the present but also as the software continues to evolve.
            </li>
        </ol>
        </br>
        <p>In summary, launching a new course with Tally Prime is essential to provide our learners with the most current and valuable skills, aligning our offerings with industry standards and enhancing the overall quality of education we provide.</p>`,
        certification: `<ul>
        <li>KLiC courses are recognised by Yashwantrao Chavan Maharashtra Open University (YCMOU).</li>
        <li>MKCL provides certificate to the KLiC learner after his/her successful course completion.</li>
        <li>Yashwantrao Chavan Maharashtra Open University (YCMOU) provides mark sheet to successfully passed KLiC learners (Jurisdiction: Maharashtra).</li>
        </ul>`,
        objective:
          "This course is designed to provide participants with a thorough understanding of Tally Prime with GST. By the end of the course, participants will be proficient in accounting, inventory management, and taxation using Tally Prime. They will learn to set up masters, process transactions, generate reports, manage GST compliance, handle payroll, and utilize advanced features effectively. Through practical case studies, participants will gain the skills needed to apply their knowledge in real-world scenarios.",

        syllabus: `
        The syllabus covers the following skills:
        <ul style="padding-left:30px">
        <li>Learn to make accurate and quick reports</li>
        <li>Become proficient in GST, voucher, currency, inventories, and excise reports</li>
        <li>Gain skills in business management</li>
        <li>Develop proficiency in data management</li>
        </ul>`,

        topics: `<h2 style="font-weight: bold;">KLiC Tally Prime with GST</h2>
        <ul style="padding-left: 30px;">
            <li>Introduction to Accounting</li>
            <li>Introduction of Tally Prime</li>
            <li>Maintaining Data in Tally Prime</li>
            <li>Accounting Master Setup</li>
            <li>Inventory Master Setup</li>
            <li>Voucher Types & Chart of Accounts</li>
            <li>Multi Master & Preview of Financial Statements in Tally</li>
            <li>Accounting Voucher - Receipt & Payment</li>
            <li>Advance Accounting & Inventory Voucher</li>
            <li>Financial Statements</li>
            <li>Bill-wise Transactions</li>
            <li>Bill-wise Outstanding’s Reports</li>
            <li>Banking in Tally Prime</li>
            <li>Multi-Currency</li>
            <li>Purchase Order Process</li>
            <li>Advance Purchase Order</li>
            <li>Sales Order</li>
            <li>Advance Sales Order</li>
            <li>Advance Inventory</li>
            <li>Accounting & Inventory Case Study</li>
            <li>Manufacturing Process</li>
            <li>Case Study - Batchwise Manufacturing</li>
            <li>POS Voucher and Other Vouchers</li>
            <li>Group Company Creation</li>
            <li>Data Management</li>
            <li>Data Export Import</li>
            <li>Tally Remote Access</li>
            <li>Payroll Setup</li>
            <li>Payroll Transaction</li>
            <li>Payroll Reports</li>
            <li>GST Introduction</li>
            <li>GST Master Setup in Tally</li>
            <li>GST - Intrastate Supply</li>
            <li>GST - Interstate Supply</li>
            <li>GST Advance Transaction & Reports</li>
            <li>TDS Compliance</li>
            <li>Case Study - GST & TDS</li>
            <li>Year End Audit Transaction</li>
            <li>Tally Report - Accounting</li>
            <li>Tally Report - Inventory & Others</li>
        </ul>`,
        price: "6200",
        duration: "120 Hours",
        level: "Expert",
      },
    ],
  },
  {
    category: "Designing",
    url: "designing",
    categoryIcon: "../assets/categories/design.png",
    courses: [
      {
        url: "klic-content-illustration",
        name: "KLiC Content Illustration",
        icon: "../assets/courses/content.svg",
        content: `Every brand needs their original content and it is always high on their priority list. With the massive amount of available content, it is difficult stand out. Illustrations can help you to make it possible. With illustrations, you can create the image you need. Effective illustrations catch the eye and help to tell a story.
        </br></br>
        After this course, the learner can be a Designer, Illustrator, Background Artist and Key-frame Artist for Animations.`,
        certification: `<ul>
        <li>KLiC courses are recognised by Yashwantrao Chavan Maharashtra Open University (YCMOU).</li>
        <li>MKCL provides certificate to the KLiC learner after his/her successful course completion.</li>
        <li>Yashwantrao Chavan Maharashtra Open University (YCMOU) provides mark sheet to successfully passed KLiC learners (Jurisdiction: Maharashtra).</li>
        </ul>`,
        objective:
          "Business Card, Labels, Letterhead, Coupon, Identity Card, Jewel Case Design, Book Mark, Sticker, Envelope, Magazine Advertisement",
        syllabus: `<h2 style="font-weight: bold;">The syllabus covers the following skills:</h2>
        The Working Place, Customizing, Tools, Layer And Layer Options, Pen, Pencils and Paths, Using the Brush and Paint, Specialized Tools, Getting Creative with Types, Using the Symbols, Designing Graphics, Effects and Filters, Playing With Palettes Select, View and Object, Automating, Exporting, Web and Devices`,
        topics: `<h2 style="font-weight: bold;">Basics of Illustrator CS4</h2>
        <ul style="padding-left: 30px;">
            <li>Introduction</li>
            <li>The Working Place</li>
            <li>Customizing</li>
            <li>Tools</li>
            <li>Layer And Layer Options</li>
            <li>Pen, Pencils and Paths</li>
            <li>Using the Brush and Paint</li>
            <li>Specialized Tools</li>
            <li>Getting Creative with Types</li>
            <li>Using the Symbols</li>
            <li>Designing Graphics</li>
            <li>Effects and Filters</li>
            <li>Playing With Palettes</li>
            <li>Select, View and Object</li>
            <li>Automating</li>
            <li>Output</li>
            <li>Exporting</li>
            <li>Web and Devices</li>
            <li>Settings</li>
        </ul>
        `,
        price: "6200",
        duration: "120 Hours",
        level: "Intermediate",
      },
      {
        url: "klic-dtp",
        name: "KLiC DTP (Adobe & CorelDRAW)",
        icon: "../assets/courses/dtp.svg",
        content: `The KLiC DTP (Adobe and CorelDRAW) 120 hours course is designed to equip learners with the essential skills needed for effective desktop publishing using industry-standard tools like Adobe and CorelDRAW. This comprehensive 120-hour program delves into the intricacies of design, layout, and graphics manipulation, providing a solid foundation for aspiring graphic designers and desktop publishers.
        </br></br>
        This 120-hour course is structured to provide a balance between theoretical knowledge and practical application, making it suitable for beginners and those looking to enhance their existing skills in desktop publishing. Learners of the KLiC DTP program emerge with a comprehensive skill set, ready to make a meaningful impact in the dynamic field of graphic design and desktop publishing.`,
        certification: `<ul>
        <li>KLiC courses are recognised by Yashwantrao Chavan Maharashtra Open University (YCMOU).</li>
        <li>MKCL provides certificate to the KLiC learner after his/her successful course completion.</li>
        <li>Yashwantrao Chavan Maharashtra Open University (YCMOU) provides mark sheet to successfully passed KLiC learners (Jurisdiction: Maharashtra).</li>
        </ul>`,
        objective:
          "Image color corrections, Photo Collage, Photo Manipulations, Image enhancement with filters.",
        syllabus:
          "The Working Place, Customizing, Image and Color Modifications, Tools, Using the Brush and Paint, Working with Selections, Scaling, Knowing the Layers, Filter Gallery, Playing with Palettes.",
        topics: `<h2 style="font-weight: bold;">Introduction to DTP</h2>
<ul style="padding-left: 30px;">
    <li>Purpose of Learning DTP</li>
    <li>Getting introduced to the DTP Course
        <ul style="padding-left: 30px;">
            <li>Understanding what Desktop Publishing is</li>
            <li>Knowing where DTP is used</li>
            <li>Understanding who uses DTP</li>
            <li>DTP in marketing: importance and usage</li>
            <li>Learning DTP formats and outputs</li>
            <li>Taking real-life examples of DTP</li>
            <li>Understanding print and online in DTP</li>
        </ul>
    </li>
    <li>Learning about tools for DTP</li>
    <li>Working with images
        <ul style="padding-left: 30px;">
            <li>How to choose images</li>
            <li>Understanding and using the image Resources</li>
            <li>What are copyrights?</li>
        </ul>
    </li>
</ul>
<br/>

<h2 style="font-weight: bold;">Adobe Photoshop CC</h2>
<ul style="padding-left: 30px;">
    <li>Learning about Adobe Photoshop CC</li>
    <li>Learning Adobe Photoshop CC features and tools</li>
    <li>Editing and creating photos quickly</li>
    <li>Other important enhancements</li>
    <li>Creative Cloud Docs</li>
    <li>Getting started with Photoshop.
        <ul style="padding-left: 30px;">
            <li>Introduction to the basics of Photoshop</li>
            <li>Introduction to Photoshop</li>
            <li>Pixels and Resolution</li>
            <li>Introduction to basic tools</li>
            <li>Exploring Adobe Photoshop workspace and customization</li>
            <li>Photoshop Interface</li>
            <li>Organizing</li>
            <li>Saving</li>
            <li>Image, Color Modification Understanding</li>
            <li>Image Modifications</li>
            <li>Color Modifications</li>
        </ul>
    </li>
    <li>Mastering Adobe Photoshop tools</li>
        <ul style="padding-left: 30px;">
            <li>Using the basic tools</li>
            <li>Using the selection tools</li>
            <li>Using the drawing and coloring tools</li>
            <li>Learning to use the tools of Adobe Photoshop</li>
            <li>Exploring drawing and coloring tools</li>
        </ul>
    </li>
    <li>Case Study - Black & White to Color</li>
    <li>Using the advanced tools</li>
    <li>Using the text tools</li>
    <li>Let's learn more about Photoshop</li>
    <li>Advancing in Adobe Photoshop skills</li>
    <li>Using the Brush and Paint</li>
    <li>Working with Selections</li>
    <li>Scaling</li>
    <li>Knowing the Layers</li>
    <li>Case Study - Photo Collage</li>
    <li>Discovering Filter Gallery filters</li>
    <li>Case Study - Greeting Card</li>
    <li>Identifying, applying color adjustments</li>
    <li>Mastering the color adjustments</li>
    <li>Additional color adjustments: Understanding</li>
    <li>Case Study - Photo Manipulation</li>
</ul>
<br/>
<h2 style="font-weight: bold;">CorelDRAW</h2>
<ul style="padding-left: 30px;">
    <li>Learning the basics of CorelDRAW</li>
    <li>Understanding what CorelDRAW is</li>
    <li>Exploring the CorelDRAW user interface</li>
    <li>Understanding the Basics in CorelDRAW</li>
    <li>Getting to learn more about CorelDRAW</li>
    <li>Working with the Page Set-Up</li>
    <li>Working with files and documents</li>
    <li>Getting Started with CorelDRAW</li>
    <li>Knowing and using the basic controls</li>
    <li>Understanding the Color Palette</li>
    <li>Understanding the Viewing Options</li>
    <li>Understanding the psychology of colors</li>
    <li>Exploring the Color Palette in CorelDRAW</li>
    <li>Understanding what Harmonious colors are</li>
    <li>Working with colors</li>
    <li>Working with the Color Palette</li>
    <li>Exploring the use of various color fills</li>
    <li>Working with Objects in CorelDRAW</li>
    <li>Working with other Fill tools</li>
    <li>Manipulating the objects</li>
    <li>Inserting and formatting text</li>
    <li>Case Study: Letterhead with Logo</li>
    <li>Learning to design a Letterhead with Logo</li>
    <li>Settings for Typing in Shree-Lipi</li>
    <li>Case Study: Visiting Card</li>
    <li>Creating a Visiting Card in CorelDraw</li>
    <li>Formatting the Visiting Card in CorelDraw</li>
    <li>CorelDRAW controls and features: Understanding</li>
    <li>Working with Layers</li>
    <li>Case Study: Sticker</li>
    <li>Designing a Sticker in CorelDRAW</li>
    <li>Getting to know other controls and features in CorelDRAW</li>
    <li>Working with Tables</li>
    <li>Case Study: Flex Banner</li>
    <li>Creating a Flex Banner in CorelDraw</li>
    <li>Modifying the Flex Banner design</li>
    <li>Case Study: Wedding Card</li>
    <li>Designing a Wedding Card in CorelDRAW</li>
    <li>Wedding Card design: Completing and exporting</li>
    <li>Case Study: Flyer with Coupon</li>
    <li>Designing a Flyer with Coupon</li>
    <li>Completing the design of Flyer with Coupon</li>
    <li>Learning about the other features</li>
    <li>Studying about Bitmaps</li>
    <li>Going 3D</li>
</ul>
<br/>

<h2 style="font-weight: bold;">Starting a Client based Project</h2>
<ul style="padding-left: 30px;">
    <li>Meeting the client</li>
    <li>Client Brief</li>
    <li>Content and Graphics</li>
    <li>Marketing Plan</li>
    <li>Look n feel</li>
    <li>How to start designing an output</li>
    <li>What are primary sketches?</li>
    <li>The process of designing an output</li>
    <li>What is a Moodboard?</li>
    <li>Outcome</li>
</ul>
<br/>

<h2 style="font-weight: bold;">Printing and scanning basics: Understanding</h2>
<ul style="padding-left: 30px;">
    <li>Listing various types of printers</li>
    <li>Printers based on technology: Learning</li>
    <li>Printers based on characteristics: Learning</li>
    <li>Learning about the different printer qualities</li>
    <li>Case Study: Brochure</li>
    <li>Learning about shapes and designing a Brochure</li>
    <li>Designing Shapes, Completing Brochure design</li>
</ul>
<br/>
<h2 style="font-weight: bold;">Printing Concepts</h2>
<ul style="padding-left: 30px;">
    <li>Learning about the printing concepts</li>
    <li>Learning about the printing terms</li>
    <li>Case Study: Product Box Package</li>
    <li>Creating a nice Product Box Package design</li>
    <li>Formatting, exporting Product Box design</li>
    <li>Understanding DPI & Resolution</li>
    <li>Defining the printing fonts</li>
    <li>Identifying the ideal file formats</li>
    <li>Learning about the printing technologies</li>
    <li>Identifying various printing methods</li>
    <li>Studying Digital Printing vs. Offset Printing</li>
    <li>Defining the types of printer papers</li>
    <li>Types of printer papers: Understanding</li>
    <li>Suitable papers for printer types: Understanding</li>
    <li>Attributes of printer papers: Learning</li>
    <li>Post Press – Print Project Finishing</li>
    <li>Completing a Print Project</li>
    <li>Case Study: Book Cover</li>
    <li>Creating, designing Book Cover in new document</li>
    <li>Finishing Book Cover design</li>
</ul>
<br/>
<h2 style="font-weight: bold;">Introduction to new version of CorelDRAW</h2>
<ul style="padding-left: 30px;">
    <li>Introduction to CorelDRAW</li>
    <li>Introduction to new version of CorelDRAW</li>
    <li>Choosing a workspace in CorelDRAW</li>
    <li>CorelDRAW default workspace: Understanding and use</li>
    <li>Case Study: Newspaper advertising</li>
    <li>Designing an advertisement in CorelDRAW</li>
    <li>Enhancing and completing the Newspaper advertising</li>
    <li>Introduction to CorelDRAW</li>
    <li>Customizing Toolbars</li>
    <li>Customizing the Property Bar</li>
    <li>Corel Font Manager</li>
    <li>Customizing Workspace Appearance</li>
    <li>Getting to know the new CorelDRAW</li>
    <li>Design anything easily using CorelDRAW</li>
    <li>Suitable platforms for using CorelDRAW</li>
    <li>Design graphics with purpose</li>
    <li>Bring out your creativity</li>
    <li>Other enabling tools</li>
    <li>Exploring the new features of CorelDRAW</li>
    <li>Using CorelDRAW smartly for impressive work</li>
    <li>Using the shortcut keys for smart work</li>
    <li>Case Study- Hoarding Design</li>
    <li>Objective</li>
    <li>Designing Hoarding in new CorelDRAW document</li>
    <li>Enhancing the Hoarding design</li>
    <li>Summary</li>
    <li>Outcome</li>
    <li>Exploring new features</li>
    <li>Enable powerful machine learning</li>
    <li>Using cutting-edge typography</li>
    <li>Some user-inspired enhancements</li>
    <li>Putting these features into practice</li>
    <li>Career opportunities</li>
    <li>Designing field: Various job opportunities</li>
    <li>Requirements to start your designing career</li>
    <li>Introduction to building blocks of publication</li>
    <li>Role of CorelDRAW in Print Media</li>
    <li>Combine Case Study Adobe Photoshop-CorelDRAW</li>
    <li>Case Study - Brochure</li>
    <li>Objective</li>
    <li>Designing Tri-fold Brochure in Adobe Photoshop</li>
    <li>Modifying Tri-fold Design in CorelDRAW</li>
    <li>Summary</li>
    <li>Case Study-Magazine Cover and Back</li>
    <li>Objective</li>
    <li>Creating Magazine Cover and Back Page: Beginning</li>
    <li>Enhancing, exporting Magazine Cover design</li>
    <li>Summary</li>
    <li>Case Study-e-Book</li>
    <li>Objective</li>
    <li>Creating an e-Book in Inkscape</li>
    <li>Formatting and completing the e-Book design</li>
    <li>Summary</li>
    <li>Case Study-Business Proposal</li>
    <li>Objective</li>
    <li>Creating a Business Proposal in CorelDRAW</li>
    <li>Modifying, finishing Business Proposal</li>
    <li>Summary</li>
    <li>Understanding the objective behind merging data</li>
    <li>CSV file and its role in data merging: Understanding</li>
    <li>CorelDRAW for data merging: Understanding utility</li>
    <li>Data Merging from a Microsoft Excel document</li>
    <li>Data Merging from a Microsoft Word file</li>
    <li>Data Merging from a PDF file</li>
    <li>Learning the basics of Adobe Illustrator</li>
    <li>Getting introduced to Adobe Illustrator</li>
    <li>Learning about colors in Illustrator</li>
    <li>Basic tools of Illustrator: Introduction</li>
    <li>The Working Place</li>
    <li>Getting to know the user interface</li>
    <li>Customizing the designs</li>
    <li>Organizing files</li>
    <li>Saving files</li>
    <li>Understanding Artboards</li>
    <li>Tools</li>
    <li>Learning about the Selection tools</li>
    <li>Drawing and painting tools: Introduction</li>
    <li>Efficient use of drawing, painting tools</li>
    <li>Tools</li>
    <li>Mastering the use of artistic tools</li>
    <li>Using artistic tools</li>
    <li>Learning best way to use artistic tools</li>
    <li>Learning more tools for drawing, painting</li>
    <li>Using the painting and drawing tools</li>
    <li>Tools</li>
    <li>Mastering artistic tools and more</li>
    <li>Using new drawing and painting tools</li>
    <li>Discovering the Type tool</li>
    <li>Using the Zoom and Moving tools</li>
    <li>Introduction to Layers and its options</li>
    <li>Layers, options for better organization</li>
    <li>Case Study- Business Card</li>
    <li>Creating a Business Card</li>
    <li>Using Pen, Pencils, and Paths</li>
    <li>Paths and options: Learning more</li>
    <li>Using the Brush and Paint</li>
    <li>Exploring the Brushes</li>
    <li>Exploring the Brush Library</li>
    <li>Painting in Illustrator</li>
    <li>Blending in Adobe Illustrator</li>
</ul>
</br>
<h2 style="font-weight: bold;">Adobe Illustrator</h2>
<ul style="padding-left: 30px;">
    <li>Mastering the next level in Adobe Illustrator</li>
    <li>Using the specialized tools</li>
    <li>Using the Reshaping tools</li>
    <li>Using the specialized tools</li>
    <li>Using the Graph Tools</li>
    <li>Using the slicing and cutting tools</li>
    <li>Using the Symbol tool</li>
    <li>Getting Creative with Types</li>
    <li>Using and Modifying a Type</li>
    <li>Using Character and Paragraph Palettes</li>
    <li>Understanding what Area Type is</li>
    <li>Understanding what Path Type is</li>
    <li>Using the Symbols</li>
    <li>Learning to use the Symbol option</li>
    <li>Exploring the Symbol Library</li>
    <li>Designing Graphics</li>
    <li>Using Graphics</li>
    <li>Designing Graphics</li>
    <li>Applying graphic styles</li>
    <li>Using and applying effects and filters</li>
    <li>Applying Effects</li>
    <li>Using and applying effects and filters</li>
    <li>Using Effects for enhancement</li>
    <li>Playing With Palettes</li>
    <li>Arranging the Workspace</li>
    <li>Case Study- Coupon</li>
    <li>Creating a coupon design in Adobe Illustrator</li>
    <li>Learning about various color palettes</li>
    <li>Printing and its concepts</li>
    <li>Understanding the basics of printing</li>
    <li>Understanding the Artboard printing techniques</li>
    <li>Learning about colors in printing</li>
    <li>Exporting the design output</li>
    <li>Exporting documents in different file formats</li>
    <li>Exporting documents for Adobe Applications</li>
    <li>Elements of print-ready output for publication</li>
    <li>Understand Illustrator's role in print media</li>
    <li>Working smartly in Adobe Illustrator - I</li>
    <li>Using the shortcut keys for smart work</li>
  </ul>
<br/>
  <h2 style="font-weight: bold;">Adobe InDesign</h2>
  <ul style="padding-left: 30px;">
    <li>Getting started with Adobe InDesign</li>
    <li>Getting introduced to Adobe InDesign</li>
    <li>Understanding Quick Preview</li>
    <li>Getting Started with InDesign</li>
    <li>Exploring the Workspace</li>
    <li>Getting started with Adobe InDesign</li>
    <li>Using Toolbox</li>
    <li>Working with Documents</li>
    <li>Working with Panels</li>
    <li>Getting Help while you work</li>
    <li>Creating and Viewing a Document</li>
    <li>Creating a New Document</li>
    <li>Creating a New Document from a Template</li>
    <li>Creating New Document Using Presets</li>
    <li>Setting up a Document</li>
    <li>Using the Zoom and Hand tools</li>
    <li>Working with Rulers</li>
    <li>Working with Guides</li>
    <li>Creating and Viewing a Document</li>
    <li>Using Smart Guides</li>
    <li>Using Grids</li>
    <li>Working with the Info Panel</li>
    <li>Creating and Displaying Workspaces</li>
    <li>Changing the Display View</li>
    <li>Using Undo and Redo</li>
    <li>Managing the pages and books in an InDesign document</li>
    <li>Using the Pages Panel</li>
    <li>Inserting pages</li>
    <li>Navigating pages</li>
    <li>Deleting or Moving Pages</li>
    <li>Working with Page Spreads</li>
    <li>Managing the pages and books in an InDesign document</li>
    <li>Creating Master pages</li>
    <li>Working with Master Pages</li>
    <li>Working with Page Number and Sections</li>
    <li>Working with Chapter Numbers</li>
    <li>Creating and Using Text Variables</li>
    <li>Creating a Book</li>
    <li>Managing Books</li>
    <li>Creating a Table of Contents</li>
    <li>Adjusting Layouts</li>
    <li>Inserting and arranging text in InDesign</li>
    <li>Using the Type tool</li>
    <li>Adding Text to Frame</li>
    <li>Inserting and arranging text in InDesign</li>
    <li>Threading text</li>
    <li>Editing text</li>
    <li>Using the Find/Change feature</li>
    <li>Working with Glyphs and Special Characters</li>
    <li>Using Spell-check and Language Dictionaries</li>
    <li>Formatting and enhancing the text</li>
    <li>Formatting text</li>
    <li>Changing Fonts and Font Size</li>
    <li>Text Leading</li>
    <li>Kerning and tracking</li>
    <li>Formatting paragraphs</li>
    <li>Aligning text</li>
    <li>Bullets and Numbering</li>
    <li>Text Composition</li>
    <li>Tabs and indents</li>
    <li>Mastering the next level in Adobe InDesign skills</li>
    <li>Placing and working with Graphics</li>
    <li>Placing Graphics</li>
    <li>Placing Multiple Graphics</li>
    <li>Using the Links Panel</li>
    <li>Embedding links</li>
    <li>Editing Original Graphics</li>
    <li>Case Study- Flyer</li>
    <li>Setting up Flyer in Adobe InDesign</li>
    <li>Modifying and finishing the Flyer design</li>
    <li>Setting Frame-fitting Options</li>
    <li>Setting compound paths</li>
    <li>Colorizing images</li>
    <li>Controlling Graphics Display Performance</li>
    <li>Displaying XMP Graphics Information</li>
    <li>Inserting and working with objects</li>
    <li>Creating a New Object</li>
    <li>Creating Lines</li>
    <li>Selecting objects</li>
    <li>Using Basic Strokes and Fills</li>
    <li>Using advanced strokes</li>
    <li>Using Transparency</li>
    <li>Adding Drop Shadows</li>
    <li>Applying Feathering</li>
    <li>Case Study- Menu Card</li>
    <li>Designing Menu Card using Type tool</li>
    <li>Enhancing, completing Menu Card design creatively</li>
    <li>Using Other Effects to Format Objects</li>
    <li>Using the Eyedropper tool</li>
    <li>Case Study- Brochure</li>
    <li>Create Brochure in Adobe InDesign, set up document</li>
    <li>Enhancing and completing the Brochure design</li>
    <li>Arranging, managing, and transforming objects</li>
    <li>Stacking objects</li>
    <li>Creating a New Layer</li>
    <li>Using Layers</li>
    <li>Nesting Objects</li>
    <li>Editing Frame and Path Shapes</li>
    <li>Understanding Corner Options</li>
    <li>Grouping and Locking Objects</li>
    <li>Aligning and Distributing</li>
    <li>Using Anchored Objects</li>
    <li>Transforming Objects</li>
    <li>Duplicating and Flipping Objects</li>
    <li>Working with Points and Paths</li>
    <li>Drawing with the Pen Tool</li>
    <li>Selecting and Moving Points and Segments</li>
    <li>Converting Points</li>
    <li>Adding and Deleting Anchor Points</li>
    <li>Splitting Paths</li>
    <li>Using the Smooth tool</li>
    <li>Using the Pencil Tool</li>
    <li>Working with Pathfinder</li>
    <li>Joining and Closing Anchor Points</li>
    <li>Reshape Paths</li>
    <li>Creating a Compound Path</li>
    <li>Case Study- Book Cover</li>
    <li>Creating a Book Cover in Adobe InDesign</li>
    <li>Finalizing book cover design in InDesign</li>
    <li>Applying and Managing Color</li>
    <li>Working With Color Modes</li>
    <li>Applying Color</li>
    <li>Using the Eyedropper Tool</li>
    <li>Working with the Color Panel</li>
    <li>Working with Swatches</li>
    <li>Tints</li>
    <li>Applying and adjusting the colors</li>
    <li>Applying Gradients</li>
    <li>Mixing Inks</li>
    <li>Using Colors from Imported Graphics</li>
    <li>Inserting and working with Tables</li>
    <li>Creating a Table</li>
    <li>Selecting Rows and Columns</li>
    <li>Adding and Deleting Rows and Columns</li>
    <li>Formatting a Table</li>
    <li>Formatting Cells</li>
    <li>Adding Headers and Footers</li>
    <li>Applying Table Styles</li>
    <li>Placing Graphics in Cells</li>
    <li>Adding Microsoft Word and Excel Tables</li>
    <li>Applying and working with Styles</li>
    <li>Using the Styles Panels</li>
    <li>Applying Character Styles</li>
    <li>Applying Paragraph Styles</li>
    <li>Creating GREP Style</li>
    <li>Creating a Style Group</li>
    <li>Using Nested Styles</li>
    <li>Adobe InDesign for bulk output creation</li>
    <li>Get job-ready with best software training.</li>
    <li>Using Object Styles</li>
    <li>Creating Table and Cell styles</li>
    <li>Using Quick Apply</li>
    <li>Case Study- User Manual</li>
    <li>Designing a User Manual in Adobe InDesign</li>
    <li>Entering content and Finishing up User Manual</li>
    <li>Mastering the last level in InDesign</li>
    <li>Finalizing Documents</li>
    <li>Using Custom Dictionaries</li>
    <li>Finding and Changing Fonts</li>
    <li>Using Find and Change</li>
    <li>Searching for Text</li>
    <li>Searching using GREP</li>
    <li>Finalizing the InDesign documents</li>
    <li>Searching for Glyphs</li>
    <li>Searching for Objects</li>
    <li>Working with Hyphenation</li>
    <li>Keeping Lines together</li>
    <li>Changing Justification Options</li>
    <li>Changing Case</li>
    <li>Using the Story Editor</li>
    <li>Finalizing the InDesign documents</li>
    <li>Adding Footnotes</li>
    <li>Working with Notes</li>
    <li>Exporting a document in Adobe InDesign</li>
    <li>Exporting a Document</li>
    <li>Understanding Export File Formats</li>
    <li>Exporting as PDF File</li>
    <li>Case Study- News Paper Article</li>
    <li>Designing newspaper article in Adobe InDesign</li>
    <li>Exporting document in Adobe InDesign</li>
    <li>Exporting as an EPS</li>
    <li>Exporting Cross-Media Files</li>
    <li>Exporting as a JPEG</li>
    <li>Printing and Outputting a Document</li>
    <li>Printing Presets</li>
    <li>Printing a Document</li>
    <li>Setting General and Setup Print Options</li>
    <li>Printing an exported document</li>
    <li>Setting Marks and Bleed Options</li>
    <li>Setting Graphics Options</li>
    <li>Previewing Color Separation</li>
    <li>Setting Output options</li>
    <li>Setting trapping options</li>
    <li>Printing an exported document</li>
    <li>Setting Advanced Options</li>
    <li>Setting color management options</li>
    <li>Creating a print Summary</li>
    <li>Printing spreads in a booklet</li>
    <li>Using Live Preflight</li>
    <li>Inserting File Information</li>
    <li>Setting Preferences</li>
    <li>Setting General Preferences</li>
    <li>Setting Interface Preferences</li>
    <li>Setting Type Preferences</li>
    <li>Setting Advanced Type Preferences</li>
    <li>Setting Composition Preferences</li>
    <li>Setting Units and increments Preferences</li>
    <li>Dictionary Preferences</li>
    <li>Spelling Preferences</li>
    <li>Note Preferences</li>
    <li>Black Preferences</li>
    <li>Story Editor Display Preferences</li>
    <li>File Handling Preferences</li>
    <li>Setting preferences to use the application effectively.</li>
    <li>Clipboard Handling Preferences</li>
    <li>Shortcut Keys</li>
    <li>Customizing Menus</li>
    <li>Customizing Plug-ins</li>
    <li>Customizing the Control Panel</li>
    <li>Case Study- Magazine Cover Page</li>
    <li>Creating magazine cover page in Adobe InDesign</li>
    <li>Finishing up designing Magazine Cover Page</li>
    <li>Getting to know about Adobe InDesign CC</li>
    <li>Updates in Graphic Designing</li>
    <li>Required skills to design graphics.</li>
    <li>Tools for designing.</li>
    <li>Exploring new features in InDesign</li>
    <li>Designing graphics rapidly</li>
    <li>Creating quirky designs</li>
    <li>Enhancing the document</li>
    <li>The SVG image support</li>
    <li>Career opportunities for a bright future</li>
    <li>Recruitment and various job profiles</li>
    <li>Being careful and aware</li>
    <li>Requirements to begin a career.</li>
    <li>Combine Case Study Adobe Illustrator – InDesign</li>
    <li>Case Study-Entry Pass Design</li>
    <li>Objective</li>
    <li>Designing an Entry Pass in Adobe Illustrator</li>
    <li>Continuing Entry Pass design in Adobe Illustrator</li>
    <li>Completing Entry Pass design in Adobe InDesign</li>
    <li>Summary</li>
    <li>Outcome</li>
    <li>Case Study-Creating a book.</li>
    <li>Objective</li>
    <li>Designing a book in Adobe InDesign</li>
    <li>Editing in Photoshop, finalizing in Adobe InDesign</li>
    <li>Summary</li>
    <li>Working smartly in Adobe InDesign</li>
    <li>Combine Case Study Adobe Photoshop – InDesign</li>
    <li>Case Study- Bill Book</li>
    <li>Objective</li>
    <li>Setting up a document and designing a Bill Book</li>
    <li>Creating and exporting a Bill Book</li>
    <li>Summary</li>
    <li>Working smartly in Adobe InDesign</li>
    <li>Using the shortcut keys for smart work</li>
    <li>Shortcuts and Conclusion</li>
    <li>Grabbing and merging data in Adobe InDesign</li>
    <li>Understanding the objective behind merging data</li>
    <li>Exploring benefits of data merging in InDesign</li>
    <li>Listing requirements for merging data in InDesign</li>
    <li>Merging data in InDesign using data source file</li>
    <li>Understanding more about Data Merging</li>
    <li>Using three tabs in merged document creation</li>
    <li>Merging data using other source files</li>
    <li>Merging data with MS Excel document</li>
    <li>Merging data to enter numbers on tickets.</li>
    <li>Merging MS Word and PDF documents in InDesign</li>
    <li>Comparing Print and Data Merge in InDesign</li>
    <li>Understanding what asset handling is</li>
    <li>Learning the meaning and objectives</li>
    <li>Exploring InDesign's asset handling capabilities</li>
    <li>Exploring tools for managing assets in InDesign</li>
    <li>Choosing the templates smartly</li>
    <li>What are templates?</li>
    <li>Creating visuals using templates in InDesign</li>
    <li>Understanding the benefits of using templates</li>
    <li>Finding useful sites for downloading templates</li>
  </ul>
  </br>
  <h2 style="font-weight: bold;">Envato</h2>
  <ul style="padding-left: 30px;">
      <li>Learning what Envato is</li>
      <li>Understanding the use of Envato</li>
      <li>Getting the overview of user interface</li>
      <li>Learning the smart tips for working</li>
      <li>Outcome</li>
      <li>Recalling key factors of ready-to-use templates</li>
      <li>Discovering the major factors about templates</li>
      <li>Exploring when to and how to use templates.</li>
      <li>Efficient Designing within Deadlines</li>
      <li>Identifying templates for various mediums</li>
      <li>Identifying software using templates for work</li>
      <li>Understanding how templates can ease and speed up your work.</li>
      <li>Outcome</li>
      <li>Learning Presenter Media and template use</li>
      <li>What is PresenterMedia and its use?</li>
      <li>Alternatives to PresenterMedia?</li>
      <li>Use of Templates in PresenterMedia</li>
      <li>Use of Templates in other websites</li>
      <li>Interface Overview of PresenterMedia</li>
      <li>Smarter tips for PresenterMedia, other sites</li>
      <li>Outcome</li>
  </ul>
  </br>
  <h2 style="font-weight: bold;">Canva</h2>
  <ul style="padding-left: 30px;">
      <li>What is Canva and what are its uses?</li>
      <li>What are free Templates in Canva?</li>
      <li>Essential Elements in Canva</li>
      <li>Interface Overview of Canva</li>
      <li>Smart tips to work in with Canva.</li>
  </ul>
  </br>
  <h2 style="font-weight: bold;">Combine Case Study Adobe Illustrator – Scribus</h2>
  <ul style="padding-left: 30px;">
      <li>Case Study-Product Manual</li>
      <li>Objective</li>
      <li>Product Manual: Understanding and Designing</li>
      <li>Customizing and finalizing the Product Manual</li>
      <li>Summary</li>
      <li>Outcome</li>
      <li>Case Study- Story Board</li>
      <li>Objective</li>
      <li>Beginning to design a storyboard.</li>
      <li>Designing screens in storyboard with page setups</li>
      <li>Summary</li>
      <li>Outcome</li>
      <li>Copyright</li>
  </ul>`,
        price: "6200",
        duration: "120 Hours",
        level: "Expert",
      },
      {
        url: "klic-graphic-designing",
        name: "KLiC Graphic Designing",
        icon: "../assets/courses/graphic.svg",
        content: `Graphic Designer is the one who designs, creates, edits, manipulates various types of graphics.
        These graphics can be Photographs, pictures, drawings, icons, line arts, paintings etc.
        </br> 
        Using all these types of graphics, he/she can create flyers, posters, magazines, hoardings, book covers, brochures, product catalogs etc. He/She can also create web banners, buttons, icons, photomerge (collage), photo editing etc. Product packaging design, User Interface design, Corporate identity profiling are few more services a graphic designer can offer.`,
        certification: `<ul>
        <li>KLiC courses are recognised by Yashwantrao Chavan Maharashtra Open University (YCMOU).</li>
        <li>MKCL provides certificate to the KLiC learner after his/her successful course completion.</li>
        <li>Yashwantrao Chavan Maharashtra Open University (YCMOU) provides mark sheet to successfully passed KLiC learners (Jurisdiction: Maharashtra).</li>
        </ul>`,
        objective:
          "Business Card, Labels, Letterhead, Coupon, Identity Card, Jewel Case Design, Book Mark, Sticker, Envelope, Magazine Advertisement",
        syllabus:
          "Working Place, Customizing, Tools, Layer And Layer Options, Pen, Pencils and Paths, Using the Brush and Paint, Specialized Tools, Getting Creative with Types, Using the Symbols, Designing Graphics, Effects and Filters, Playing With Palettes Select, View and Object, Automating, Exporting, Web and Devices",
        topics: `<h2 style="font-weight: bold;">Adobe Illustrator</h2>
        <ul style="padding-left: 30px;">
            <li>Basics of Illustrator CS4</li>
            <li>Introduction</li>
            <li>The Working Place</li>
            <li>Customizing</li>
            <li>Tools</li>
            <li>Layer And Layer Options</li>
            <li>Pen, Pencils and Paths</li>
            <li>Using the Brush and Paint</li>
            <li>Specialized Tools</li>
            <li>Getting Creative with Types</li>
            <li>Using the Symbols</li>
            <li>Designing Graphics</li>
            <li>Effects and Filters</li>
            <li>Playing With Palettes</li>
            <li>Select, View and Object</li>
            <li>Output</li>
            <li>Exporting</li>
            <li>Web and Devices</li>
            <li>Settings</li>
        </ul><br>
        
        <h2 style="font-weight: bold;">Adobe Photoshop</h2>
        <ul style="padding-left: 30px;">
            <li>Basics of Photoshop CS4</li>
            <li>Introduction</li>
            <li>The Working Place</li>
            <li>Customizing</li>
            <li>Image and Color Modifications</li>
            <li>Tools</li>
            <li>Using the Brush and Paint</li>
            <li>Working with Selections</li>
            <li>Scaling</li>
            <li>Knowing the Layers</li>
            <li>Getting Creative With Types</li>
            <li>Masks</li>
            <li>Filter Gallery</li>
            <li>Playing With Palettes</li>
            <li>Color Adjustments</li>
            <li>Automating</li>
            <li>Web and Devices</li>
            <li>Output</li>
            <li>Settings</li>
        </ul><br>
        
        <h2 style="font-weight: bold;">Adobe InDesign</h2>
        <ul style="padding-left: 30px;">
            <li>Getting Started</li>
            <li>Creating and Viewing a Document</li>
            <li>Managing Pages and Books</li>
            <li>Managing Text</li>
            <li>Formatting Text</li>
            <li>Placing and working with Graphics</li>
            <li>Working with Objects</li>
            <li>Managing and Transforming Objects</li>
            <li>Working with Points and Paths</li>
            <li>Applying and Managing Color</li>
            <li>Working with Tables</li>
            <li>Working with Styles</li>
            <li>Finalizing Documents</li>
            <li>Exporting a Document</li>
            <li>Print and Output a Document</li>
            <li>Setting Preferences</li>
        </ul>`,
        price: "6200",
        duration: "120 Hours",
        level: "Intermediate",
        popular: true,
      },
      {
        url: "klic-photoshop",
        name: "KLiC Photoshop",
        icon: "../assets/courses/photoshop.svg",
        content: `Photoshop is a photo editing and manipulating software used to edit photos, digital drawings and paintings. This course will be a foundation for Career in Digital Arts. After successful completion of the course, the learner can attempt International Certification – Adobe Certified Expert in Photoshop.
        <br><br>
        After completing this course, the learner can work as a Graphic Designer, Graphic Editor, Illustrator, and Background Artist.`,
        certification: `<ul>
        <li>KLiC courses are recognised by Yashwantrao Chavan Maharashtra Open University (YCMOU).</li>
        <li>MKCL provides certificate to the KLiC learner after his/her successful course completion.</li>
        <li>Yashwantrao Chavan Maharashtra Open University (YCMOU) provides mark sheet to successfully passed KLiC learners (Jurisdiction: Maharashtra).</li>
        </ul>`,
        objective:
          "Red Eye correction, Photo Collage, Mapping to Object-MUG, T-Shirt Design, Photo Correction, Photo Manipulation, Cleaning Photo",
        syllabus:
          "Working Place, Customizing, Image and Color Modifications, Case Study - Black & White to Color, Using the Brush and Paint, Working with Selections, Scaling, Knowing the Layers, Filter Gallery, Color Adjustments",
        topics: `<h2 style="font-weight: bold;">Adobe Photoshop</h2>
        <ul style="padding-left: 30px;">
            <li>Basics of Photoshop</li>
            <li>Introduction</li>
            <li>The Working Place</li>
            <li>Customizing</li>
            <li>Image and Color Modifications</li>
            <li>Tools</li>
            <li>Using the Brush and Paint</li>
            <li>Working with Selections</li>
            <li>Scaling</li>
            <li>Knowing the Layers</li>
            <li>Getting Creative with Types</li>
            <li>Masks</li>
            <li>Filter Gallery</li>
            <li>Playing with Palettes</li>
            <li>Color Adjustments</li>
            <li>Automating</li>
            <li>Web and devices</li>
            <li>Animating and Slicing</li>
            <li>Output</li>
            <li>Settings</li>
            <li>Adobe Bridge</li>
        </ul>`,
        price: "6200",
        duration: "120 Hours",
        level: "Intermediate",
      },
      {
        url: "klic-video-editing",
        name: "KLiC Video Editing",
        icon: "../assets/courses/video.svg",
        content: `Video editing is a process of modifying or rearranging the segments of video from one or more video tapes to create a final piece of video. It also includes editing of soundtracks, video and film, Applying colour correction, filters and other enhancements and creating transitions between clips. After completing this course, the student will be able to perform the following roles:
        <br><br>
        <h2 style="font-weight: bold;">Career Opportunities (Industry wise):</h2>
        <ul style="padding-left:30px">
          <li>Television Studio</li>
          <li>Film Studio</li>
          <li>Advertising Studio</li>
          <li>Multimedia Companies</li>
          <li>eLearning Companies</li>
          <li>Web-Designing Companies</li>
        </ul>
        <br>
        <h2 style="font-weight: bold;">Career Paths:</h2>
        <ul style="padding-left:30px">
          <li>Television & Film Editor</li>
          <li>Video Editor</li>
          <li>Assistant Video Editor</li>
          <li>Film Compositor</li>
          <li>VFX Compositor</li>
        </ul>`,
        certification: `<ul>
        <li>KLiC courses are recognised by Yashwantrao Chavan Maharashtra Open University (YCMOU).</li>
        <li>MKCL provides certificate to the KLiC learner after his/her successful course completion.</li>
        <li>Yashwantrao Chavan Maharashtra Open University (YCMOU) provides mark sheet to successfully passed KLiC learners (Jurisdiction: Maharashtra).</li>
        </ul>`,
        objective:
          "Video Merging, Advertisement creation, video effects, Creating a Short Film",
        syllabus:
          "Importing and Capturing Footage, Timeline, Sequence and Clip Management, Basic Video Editing, Editing Audio, Mixing and Creating Effects with the Audio Mixer, Creating Transitions, Creating Titles and Graphics, Working with advanced editing techniques, Using Video Effects, Using Color mattes and Backdrops, Enhancing Video, Wrapping Up and Exporting",
        topics: `<h2 style="font-weight: bold;">Introduction to Video Editing with Premiere CS4</h2>
        <ul style="padding-left: 30px;">
            <li>Overview of Video Editing</li>
            <li>Uses of Premiere Pro CS4</li>
            <li>Features of Premiere CS4</li>
            <li>Installing Premiere Pro CS4</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Introduction to Moviemaking</h2>
        <ul style="padding-left: 30px;">
            <li>Basics of digital video and movie editing</li>
            <li>Comparing camcorder formats</li>
            <li>Understanding video codecs</li>
            <li>Shooting better video</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Getting Started with Premiere Pro CS4</h2>
        <ul style="padding-left: 30px;">
            <li>Working with workspace</li>
            <li>Customizing the Workspace in Premiere Pro CS4</li>
            <li>Working with project settings</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Importing and Capturing Footage</h2>
        <ul style="padding-left: 30px;">
            <li>Importing files as footage</li>
            <li>Importing stills</li>
            <li>Importing layered Photoshop and Illustrator files</li>
            <li>Dealing with missing media</li>
            <li>Setting up to capture from tape</li>
            <li>Logging Tapes</li>
            <li>Batch-capturing footage</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Timeline, Sequence and Clip Management</h2>
        <ul style="padding-left: 30px;">
            <li>Timeline</li>
            <li>Using Multiple Sequences</li>
            <li>Clip Project Management</li>
        </ul>
        <br/>
        <h2 style="font-weight: bold;">Basic Video Editing</h2>
        <ul style="padding-left: 30px;">
            <li>Basic Editing Concept and Tools</li>
            <li>Working with Monitor Panels</li>
            <li>Creating Insert and Overlay Edits</li>
            <li>Editing in the Timeline</li>
            <li>Setting In and Out Points in the Timeline Panel</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Editing Audio</h2>
        <ul style="padding-left: 30px;">
            <li>What is Audio?</li>
            <li>Timeline Audio Tracks</li>
            <li>Editing Audio</li>
            <li>Recording Audio</li>
            <li>Gaining, Fading and Balancing</li>
            <li>Using Audio Effects and Transitions</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Mixing and Creating Effects with the Audio Mixer</h2>
        <ul style="padding-left: 30px;">
            <li>Audio Mixer Overview</li>
            <li>Setting Track Volume & Mixing Tracks</li>
            <li>Panning & Balancing</li>
            <li>Applying effects with Audio Mixer</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Creating Transitions</h2>
        <ul style="padding-left: 30px;">
            <li>About Video Transitions (Overview)</li>
            <li>Applying transitions</li>
            <li>Adjusting Transitions</li>
            <li>Customizing Transitions</li>
            <li>Various Transitions Effects</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Creating Titles and Graphics</h2>
        <ul style="padding-left: 30px;">
            <li>Using the Titler</li>
            <li>Creating titles from templates</li>
            <li>Modifying a title</li>
            <li>Creating titles from scratch</li>
            <li>Superimposing a title</li>
            <li>Creating a title roll or crawl</li>
        </ul>
        <br/>
        <h2 style="font-weight: bold;">Working with advanced editing techniques</h2>
        <ul style="padding-left: 30px;">
            <li>Editing Utilities</li>
            <li>Editing with tool panel tools</li>
            <li>Fine tuning edits using the trim monitor panel</li>
            <li>Editing a Clip Using Clip Commands</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Using Video Effects</h2>
        <ul style="padding-left: 30px;">
            <li>Exploring the Video Effects</li>
            <li>Applying a Video Effect</li>
            <li>Using Video Effects with Keyframes</li>
            <li>Superimposing Video Clip Using Keying Video Effect</li>
            <li>Applying effects using Image Matte Keying Effects</li>
            <li>Touring Premiere Pro Video Effects</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Using Color mattes and Backdrops</h2>
        <ul style="padding-left: 30px;">
            <li>Mattes, Transparency and Opacity</li>
            <li>Create transparency, solid colors with keys mattes</li>
            <li>Matte other than the alpha channel</li>
            <li>Using more mattes</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Creating Motion Effects</h2>
        <ul style="padding-left: 30px;">
            <li>Motion effect controls in Effect Controls Panel</li>
            <li>Working with the Motion Controls</li>
            <li>Using timeline Panel to Preview & add Keyframes</li>
            <li>Editing Motion Paths</li>
            <li>Adding Effects to Motion Paths</li>
            <li>Using a clip with an Alpha Channel</li>
            <li>Creating Traveling Mattes</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Enhancing Video</h2>
        <ul style="padding-left: 30px;">
            <li>Understanding video color</li>
            <li>Color Correcting Basics</li>
            <li>Start a Color Correcting Session</li>
            <li>Using the Video Scopes</li>
            <li>Color Enhancement Effects</li>
            <li>Special color and luminance adjustments</li>
            <li>Retouch Using Photoshop and After Effects</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Wrapping Up and Exporting</h2>
        <ul style="padding-left: 30px;">
            <li>Preparing to export video</li>
            <li>Exporting to tape</li>
            <li>Exporting to Encore DVD</li>
            <li>Exporting to various formats using Adobe Encoder</li>
            <li>Sharing Your Movies Online</li>
        </ul>`,
        price: "6200",
        duration: "120 Hours",
        level: "Expert",
      },
      {
        url: "klic-web-desinging",
        name: "KLiC Web Designing",
        icon: "../assets/courses/web.svg",
        content: `A web designer is essentially in a supporting role. He / She is supposed to have sound knowledge of design fundamentals and web production techniques. He / She is involved in the project at every stage, so that he / she understands the whole project cycle.
        <br><br>
        <h2 style="font-weight: bold;">Functions of a Web Designer:</h2>
        <ol style="padding-left: 30px;">
            <li>Assists in project conceptualization</li>
            <li>Creates workflows for developers and clients</li>
            <li>Follows brand identity norms</li>
            <li>Makes effective use of typography and images</li>
            <li>Creates digital advertising assets (like banners, rollovers etc.)</li>
        </ol>`,
        certification: `<ul>
        <li>KLiC courses are recognised by Yashwantrao Chavan Maharashtra Open University (YCMOU).</li>
        <li>MKCL provides certificate to the KLiC learner after his/her successful course completion.</li>
        <li>Yashwantrao Chavan Maharashtra Open University (YCMOU) provides mark sheet to successfully passed KLiC learners (Jurisdiction: Maharashtra).</li>
        </ul>`,
        objective: "Web Layout Design, Web Banner, Advertisement, UI Design",
        syllabus:
          "Working Place, Customizing, Image and Color Modifications, Case Study - Black & White to Color, Using the Brush and Paint, Working with Selections, Scaling, Knowing the Layers, Filter Gallery, Color Adjustments",
        topics: `<h2 style="font-weight: bold;">Understanding the World of Designing</h2>
        <ul style="padding-left: 30px;">
            <li>Understanding colors and related concepts</li>
            <li>Getting introduced to typography and colors</li>
            <li>Learning how to write and build content</li>
            <li>Understanding what Client Briefs, Storyboards, Visual Mind Maps, and Marketing Plans</li>
            <li>Learning more about client briefs, Storyboards, Visual Mind Maps, etc.</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Designs and shapes</h2>
        <ul style="padding-left: 30px;">
            <li>Learning about various designs and shapes</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Basics of designs and shapes</h2>
        <ul style="padding-left: 30px;">
            <li>Understanding the basics of designs and shapes</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Basics of web designing</h2>
        <ul style="padding-left: 30px;">
            <li>Learning the basics of web designing</li>
            <li>Learning to create a few website elements</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Typography</h2>
        <ul style="padding-left: 30px;">
            <li>Understanding the rules and guidelines for typography</li>
            <li>Using Fonts and optimizing typography</li>
        </ul>
        <br/><h2 style="font-weight: bold;">Color schemes</h2>
        <ul style="padding-left: 30px;">
            <li>Identifying color schemes and combinations</li>
            <li>Exploring more about colors and the trends</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Adobe Bridge</h2>
        <ul style="padding-left: 30px;">
            <li>Learning the basics of Adobe Bridge</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Effects in Adobe Photoshop</h2>
        <ul style="padding-left: 30px;">
            <li>Creating web-ready images by applying various effects in Adobe Photoshop</li>
            <li>Learning about a tool and creating a web layout in Adobe Photoshop</li>
            <li>Learning to automate and mask an image in Adobe Photoshop</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Sitemap and wireframe</h2>
        <ul style="padding-left: 30px;">
            <li>Learning to create a sitemap and wireframe for a website</li>
            <li>Identifying how and where to use a website wireframe</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Copyright-free images</h2>
        <ul style="padding-left: 30px;">
            <li>Searching, downloading, and using free and copyright-free images</li>
            <li>Exploring stock platforms for images</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Web designing tools</h2>
        <ul style="padding-left: 30px;">
            <li>Exploring the popular web designing tools</li>
            <li>Studying more about the web designing tools</li>
        </ul>
        <br/><h2 style="font-weight: bold;">Figma</h2>
        <ul style="padding-left: 30px;">
            <li>Designing a user interface using Figma</li>
            <li>Adding content in the user interface using Figma</li>
            <li>Creating and working on a project in Figma</li>
            <li>Working with components and applying constraints in Figma</li>
            <li>Working with plugins in Figma</li>
            <li>Learning to prototype, share, and export a file in Figma</li>
            <li>Executing to prototype, share, and export a file in Figma</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">UI, layouting, and grids</h2>
        <ul style="padding-left: 30px;">
            <li>Learning about UI, layouting, and grids</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Understanding UI and UX</h2>
        <ul style="padding-left: 30px;">
            <li>Understanding what is UI and UX</li>
            <li>Exploring the field of UX and the 4 C's of designing</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Smart and efficient working</h2>
        <ul style="padding-left: 30px;">
            <li>Exploring the competencies and techniques for smart and efficient working</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Internet and related concepts</h2>
        <ul style="padding-left: 30px;">
            <li>Learning the basics of the Internet and related concepts</li>
            <li>Learning about browsers</li>
            <li>Understanding what a website and Email Client</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Adobe Dreamweaver</h2>
        <ul style="padding-left: 30px;">
            <li>Exploring more about web designing and new features of Adobe Dreamweaver</li>
            <li>Using new features of Adobe Dreamweaver and discovering what a responsive website is</li>
        <br/>
        </ul>
        <h2 style="font-weight: bold;">HTML</h2>
        <ul style="padding-left: 30px;">
            <li>Learning the basics of HTML</li>
            <li>Exploring different types of Tags in HTML</li>
            <li>Working with texts and links in HTML</li>
            <li>Working with images in HTML</li>
            <li>Exploring the forms and understanding what XHTML is</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">HTML and CSS – Case study</h2>
        <ul style="padding-left: 30px;">
            <li>Creating webpage using HTML and CSS</li>
            <li>Creating Navigation bar using CSS</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">HTML5 and CSS3</h2>
        <ul style="padding-left: 30px;">
            <li>Creating impressive webpages using HTML5, and CSS3</li>
            <li>Exploring and applying CSS Styles</li>
            <li>Working with CSS rules and AP elements</li>
            <li>Exploring what CSS3 is and using the CSS Designer Panel</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">JavaScript</h2>
        <ul style="padding-left: 30px;">
            <li>Learning about JavaScript (Hello world! and variables)</li>
            <li>Learning about JavaScript (Conditional statements)</li>
            <li>Learning about JavaScript (JS loops)</li>
            <li>Learning about JavaScript (JS project)</li>
        </ul>
        <br/>
        <h2 style="font-weight: bold;">jQuery</h2>
        <ul style="padding-left: 30px;">
            <li>Introduction to jQuery</li>
            <li>Learning more about jQuery</li>
            <li>Understanding the Events</li>
            <li>Understanding the animation effects and DOM Traversal and manipulation in jQuery</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Bootstrap</h2>
        <ul style="padding-left: 30px;">
            <li>Understanding what a responsive web design is and developing it using Bootstrap</li>
            <li>Exploring the components of Bootstrap</li>
            <li>Discovering what Forms, Breakpoints, and Containers in Bootstrap are</li>
            <li>Understanding what Grid System is in Bootstrap</li>
            <li>Working with typography and images in Bootstrap</li>
            <li>Exploring the new concepts of typography and images while working in Bootstrap</li>
            <li>Learning about the Bootstrap Homepage and other elements</li>
            <li>Exploring more about the Bootstrap</li>
            <li>Discovering and working with the About page in Bootstrap</li>
            <li>Discovering and working with the Contact page in Bootstrap</li>
            <li>Creating interactive buttons using Bootstrap</li>
            <li>Identifying various parts of a website and more</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">PHP</h2>
        <ul style="padding-left: 30px;">
            <li>Getting introduced to what PHP is, its requirements, and creating a PHP file</li>
            <li>Working with PHP variables, operators, and statements</li>
            <li>Working with PHP loops, arrays, and more</li>
            <li>Learning the types of functions in PHP</li>
        </ul>
        <br/>
        <h2 style="font-weight: bold;">MySQL</h2>
        <ul style="padding-left: 30px;">
            <li>Understanding the MySQL Databases and firebases</li>
            <li>Working with MySQL commands, databases, and statements</li>
            <li>Working with indexes and views, statements, and keywords in MySQL</li>
            <li>Exploring the procedures and types of Joins in MySQL</li>
            <li>Getting introduced to Firebase</li>
            <li>Authenticating Firebase and defining the project settings</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Designing a responsive website</h2>
        <ul style="padding-left: 30px;">
            <li>Designing a responsive website using Adobe Dreamweaver</li>
            <li>Designing and editing a responsive website using Adobe Dreamweaver</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Fluid Grid Layout - Case Study</h2>
        <ul style="padding-left: 30px;">
            <li>Designing a website using CSS3 and applying the Fluid Grid Layout</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Product-based website - Case Study</h2>
        <ul style="padding-left: 30px;">
            <li>Designing a wireframe for a product-based website - Case Study</li>
            <li>Using WordPress to design a product-based website - Case Study</li>
            <li>Customizing and completing a product-based website - Case Study</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Social media website - Case Study</h2>
        <ul style="padding-left: 30px;">
            <li>Starting with designing a social media website - Case Study</li>
            <li>Setting and customizing a social media website - Case Study</li>
            <li>Customizing a social media website - Case Study</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Website for a news channel - Case Study</h2>
        <ul style="padding-left: 30px;">
            <li>Setting and adding pages to a website for a news channel - Case Study</li>
            <li>Customizing and post news on a website for a news channel - Case Study</li>
            <li>Posting and reviewing news articles on a website for a news channel - Case Study</li>
        </ul>
        <br>
        <h2 style="font-weight: bold;">WordPress</h2>
        <ul style="padding-left: 30px;">
            <li>Designing an eCommerce website using WordPress</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Service-based website - Case Study</h2>
        <ul style="padding-left: 30px;">
            <li>Creating a wireframe for a service-based website - Case Study</li>
            <li>Setting up and arranging content for a service-based website - Case Study</li>
            <li>Formatting and reviewing a service-based website - Case Study</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">eLearning company website - Case Study</h2>
        <ul style="padding-left: 30px;">
            <li>Beginning to design a website for an eLearning company - Case Study</li>
            <li>Creating and customizing pages in a website for an eLearning company - Case Study</li>
            <li>Finalizing a website and buying a domain name for an eLearning company - Case Study</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Website for an NGO - Case Study</h2>
        <ul style="padding-left: 30px;">
            <li>Beginning to design a website for an NGO</li>
            <li>Adding and customizing elements in a website for an NGO</li>
            <li>Finalizing the website design for an NGO</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Website for a food outlet - Case Study</h2>
        <ul style="padding-left: 30px;">
            <li>Starting with designing a website for a food outlet - Case Study</li>
            <li>Editing and customizing a website for a food outlet - Case Study</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">CSS - Case Study</h2>
        <ul style="padding-left: 30px;">
            <li>Designing a website using CSS - Case Study</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Domains, and Web hosting</h2>
        <ul style="padding-left: 30px;">
            <li>Starting with the basics of domains, and web hosting</li>
            <li>Exploring more about web hosting and domain names</li>
        </ul>
        <br>
        <h2 style="font-weight: bold;">Project Cycles</h2>
        <ul style="padding-left: 30px;">
            <li>Understanding what Project Cycles are</li>
            <li>Discovering what Project Scope - Planning with the client</li>
            <li>Discovering what Project Scope - Building, Optimization, Finalization</li>
            <li>Discovering what Project Scope - Launch</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Getting job-ready</h2>
        <ul style="padding-left: 30px;">
            <li>Understanding more about the web designing field, processes, and getting job-ready</li>
            <li>Exploring more about your career in web designing</li>
            <li>Listing the types of web designers and how to create ideal resumes</li>
            <li>Getting introduced to the world of employment</li>
            <li>Understanding your options for career growth</li>
            <li>Exploring various career-building options in web designing</li>
        </ul>`,
        price: "6200",
        duration: "120 Hours",
        level: "Expert",
      },
    ],
  },
  {
    category: "IT Hardware and Networking",
    url: "hardware-networking",
    categoryIcon: "../assets/categories/network.png",
    courses: [
      {
        url: "klic-cyber-security",
        name: "KLiC Cyber Security",
        icon: "../assets/courses/cyber.svg",
        content: `Cybersecurity i.e., Security in cyberspace is about technologies, processes, and policies that help to prevent as well as reduce the negative impact of events such as cyber-crimes and cyber terrorism in cyberspace.
        <br/> <br/> 
        <ul style="padding-left: 30px;">
          <li>Protects against online frauds, banking frauds, hacking, etc.</li>
          <li>The emphasis is on practical, hands-on learning of cybersecurity awareness.</li>
          <li>Designed for young aspirants aiming to shape their careers in the IT security field.</li>
        </ul>`,
        certification: `<ul>
        <li>KLiC courses are recognised by Yashwantrao Chavan Maharashtra Open University (YCMOU).</li>
        <li>MKCL provides certificate to the KLiC learner after his/her successful course completion.</li>
        <li>Yashwantrao Chavan Maharashtra Open University (YCMOU) provides mark sheet to successfully passed KLiC learners (Jurisdiction: Maharashtra).</li>
        </ul>`,
        objective: `<ul style="padding-left: 20px;">
        <li>To raise awareness about online frauds and best practices for staying safe</li>
        <li>To provide a comprehensive understanding of everyday cybersecurity skills with real-life examples</li>
        <li>To enable learners to implement security skills practically in their daily lives</li>
         </ul>`,
        syllabus: `
        <h2 style="font-weight: bold;">Course Elements:</h2>
          <ul style="padding-left: 20px;">
              <li>Securing Personal Devices and Operating Systems</li>
              <li>Securing Wi-Fi, LAN Networks</li>
              <li>Tools used for Ethical Hacking</li>
              <li>Data and Information Security</li>
              <li>Cyber Attacks and their Types</li>
          </ul>
          <br/> 
          <h2 style="font-weight: bold;">Software/Tools Covered:</h2>
          <ul style="list-style-type: disc; padding-left: 20px;">
              <li>Avast Antivirus</li>
              <li>Comodo Firewall</li>
              <li>Angry IP Scanner</li>
              <li>Kali Linux OS</li>
              <li>VirtualBox software to install Kali Linux on VM</li>
              <li>Nmap tool</li>
              <li>Wireshark tool</li>
          </ul>`,
        topics: `<h2 style="font-weight: bold;">Cybersecurity Techniques</h2>
        <ul style="padding-left: 30px;">
            <li>Overview of Computer Viruses</li>
            <li>Downloading and Installing Good Antivirus Software</li>
            <li>Rootkits</li>
            <li>Botnets</li>
            <li>Ransomware</li>
            <li>Securing Personal computers and Operating Systems</li>
            <li>Patch Management</li>
            <li>Patch Deployment and verification</li>
            <li>Installing and configuring Open Source Firewall</li>
            <li>Network Intrusion Detection and Prevention System</li>
            <li>Network Load Balancers</li>
            <li>Setting LAB environment</li>
            <li>VPN Configuration</li>
            <li>Types of Cyber Attacks</li>
            <li>Phishing and Spoofing attacks</li>
            <li>Social Engineering</li>
            <li>Using Network Packet Analyzers</li>
            <li>Ethical Hacking</li>
            <li>Foot printing</li>
            <li>Open Source Security Scanning tools like NMAP</li>
            <li>Using Kali Linux for exploring various Ethical Hacking Tools</li>
        </ul>
        `,
        price: "2500",
        duration: "60 Hours",
        level: "Begineer",
        popular: true,
      },
      {
        url: "klic-desktop-support",
        name: "KLiC Desktop Support",
        icon: "../assets/courses/desktop.svg",
        content: `A desktop support technician can work with companies/enterprises having an IT infrastructure including desktops and laptops or the companies who are providing operating system sales and support.
        <br><br>
        <h2 style="font-weight: bold;">Career Opportunities for Desktop Support Technicians:</h2>
        <ul style="padding-left: 30px;">
            <li>Companies/enterprises with IT infrastructure including desktops and laptops</li>
            <li>Companies providing operating system sales and support</li>
        </ul>
        `,
        certification: `<ul>
        <li>KLiC courses are recognised by Yashwantrao Chavan Maharashtra Open University (YCMOU).</li>
        <li>MKCL provides certificate to the KLiC learner after his/her successful course completion.</li>
        <li>Yashwantrao Chavan Maharashtra Open University (YCMOU) provides mark sheet to successfully passed KLiC learners (Jurisdiction: Maharashtra).</li>
        </ul>`,
        objective: `The course aims to prepare learners for careers as Desktop Support Technicians by providing practical skills in installing, configuring, and managing operating systems, including Windows and Ubuntu, along with proficiency in device management and backup configuration.`,
        syllabus: `Installing Windows 7 OS, Installing Ubuntu on a PC, Installing Windows server 2008 R2, Creating Virtual OS Environment, Creating Windows 7 Virtual Operating System, Managing Devices and Drivers in an OS, Configuring backups in Windows 7, Capturing Disk Image - Backup, Capturing Disk Image -Restore, Configuring Regional Language Settings
        <br><br>  
        <b>Tools</b>: Operating system installation utilities, Virtual Operating systems, Hard disk analyzing tools, Remote desktop softwares, Disk partitioning tools, Windows users and file management, Regional language typing tools`,
        topics: `<h2 style="font-weight: bold;">Operating Systems</h2>
        <ul style="padding-left: 30px;">
            <li>Overview of Operating system</li>
            <li>Types of Operating Systems</li>
            <li>Overview of Windows 8 Operating system</li>
            <li>Overview of Windows 10 Operating system</li>
            <li>Installing Windows 7 Operating system</li>
            <li>Installing Ubuntu on a PC</li>
            <li>Installing Windows server 2008 R2</li>
            <li>Creating Windows 10 Bootable Pen drive</li>
            <li>Installing Windows 10 Operating system</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Creating Virtual Operating System Environment</h2>
        <ul style="padding-left: 30px;">
            <li>Virtualization</li>
            <li>Creating Virtual Operating System Environment</li>
            <li>Creating Windows 7 Virtual Operating System</li>
            <li>Creating Ubuntu Virtual Operating System</li>
            <li>Configuring Virtual Operating System Environment</li>
            <li>Configuring Windows 7 Virtual Machine</li>
            <li>Using electa Screen Recorder and Format Factory</li>
            <li>Configuring Ubuntu Virtual Machine</li>
            <li>Creating ISO File from a Data CD or DVD</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Managing Users and Groups</h2>
        <ul style="padding-left: 30px;">
            <li>Users in Linux</li>
            <li>Users in Windows</li>
            <li>Creating Users and Groups in Linux</li>
            <li>Creating Users and groups in Windows</li>
            <li>Managing Users and Groups in OS</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">File System in OS</h2>
        <ul style="padding-left: 30px;">
            <li>File System in Linux</li>
            <li>File System in Windows</li>
            <li>Assigning File Permissions in Linux</li>
            <li>Combining Shared Folder and NTFS Permissions in Windows</li>
            <li>Files and Folders Permissions in Windows</li>
            <li>File System in OS</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Disks and Devices Management</h2>
        <ul style="padding-left: 30px;">
            <li>Partitioning a Hard Disk</li>
            <li>Managing Devices and Drivers in an OS</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Configuring backups and System images</h2>
        <ul style="padding-left: 30px;">
            <li>Configuring backups in Windows Operating System</li>
            <li>Capturing Disk Image -Backup</li>
            <li>Capturing Disk Image -Restore</li>
            <li>Configuring Backups in Windows Operating System</li>
            <li>Configuring Backups</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Post Installation configurations and maintenance of an OS</h2>
        <ul style="padding-left: 30px;">
            <li>Configuring Regional Language Settings</li>
            <li>Configuring Remote Desktop Settings</li>
            <li>Enabling Regional language settings</li>
            <li>Installing Google Input Tool for Hindi Typing</li>
            <li>Enabling Remote Desktop Settings</li>
            <li>Configuring Disk Analyzing tool</li>
            <li>Configuring Performance Tuning Tool for a PC</li>
            <li>Hardware Monitoring Tools for Operating Systems</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Managing and Maintaining Windows 7 Desktop OS</h2>
        <ul style="padding-left: 30px;">
            <li>Migrating to Windows7</li>
            <li>Performance settings in Windows7</li>
            <li>Problem steps recorder</li>
            <li>Remote Assistance</li>
            <li>Securing Browser in Windows7</li>
            <li>Updating Windows7</li>
        </ul>
        `,
        price: "6200",
        duration: "120 Hours",
        level: "Intermediate",
      },
      {
        url: "klic-hardware-support",
        name: "KLiC Hardware Support",
        icon: "../assets/courses/hardware.svg",
        content: `As a hardware support technician, it is essential to have a good understanding of operating systems (OS) and how they interact with hardware. In addition to knowledge of different operating systems, it is also important to have a good understanding of requirements for Operating System installations, actual installation steps of an Operating System, Windows Operating system features, Hard disk partitioning etc. Also, it is always beneficial to have some basic knowledge of networking concepts.
        <br><br>
        In simple words Hardware Support Technician is the one who installs, Troubleshoot, repairs, maintains various types of Hardware IT assets. These IT Hardware assets can be Desktops, Laptops, Printers, Scanners, Power supply, RAM, Cooling Systems, and Monitors.`,
        certification: `<ul>
        <li>KLiC courses are recognised by Yashwantrao Chavan Maharashtra Open University (YCMOU).</li>
        <li>MKCL provides certificate to the KLiC learner after his/her successful course completion.</li>
        <li>Yashwantrao Chavan Maharashtra Open University (YCMOU) provides mark sheet to successfully passed KLiC learners (Jurisdiction: Maharashtra).</li>
        </ul>`,
        objective:
          "The KLiC Hardware Support course aims to equip learners with the skills needed to work as IT hardware support technicians, covering hardware troubleshooting, assembly, and maintenance.",
        syllabus: ` Identifying PC Components, Identifying System Unit Components, Identifying Storage Devices, Assembling a PC, Installing and Configuring a Printer, Troubleshooting and Maintaining Printers, Troubleshooting PC Hardware, Managing safety Hazards, Communication and Professionalism.
        <br><br>  
        <b>Tools</b>: PC Hardware, Hardware Monitoring Tools, Multi meters`,
        topics: `<h2 style="font-weight: bold;">Introduction to Operating Systems</h2>
        <ul style="padding-left: 30px;">
            <li>Introduction to Operating Systems</li>
            <li>Types of Operating Systems</li>
            <li>Overview of Microsoft Operating System</li>
            <li>Introduction to Linux operating system</li>
            <li>Client vs Server Operating System</li>
            <li>Installing software for Writing CD and creating ISO images</li>
            <li>Windows 10 Operating System</li>
            <li>Windows 10 installation</li>
            <li>Types of Partitions</li>
            <li>Basics of Computer Networking</li>
            <li>Different types of Internet Connectivity</li>
            <li>Difference between Static and Dynamic IP Address</li>
            <li>Configuring Wi-Fi network</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Introduction to IT Hardware</h2>
        <ul style="padding-left: 30px;">
            <li>Identifying System Unit Components</li>
            <li>Input Hardware Devices</li>
            <li>Output Hardware Devices</li>
            <li>Basic Hardware Connections</li>
            <li>Modern Types of Connectors – USB, OTG, NFC</li>
            <li>External Hardware and Peripherals Connections</li>
            <li>Motherboard Overview</li>
            <li>Motherboard Components</li>
            <li>Understanding CPU characteristics</li>
            <li>CPU Cooling Techniques</li>
            <li>Multimedia Devices</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Overview of Storage Devices</h2>
        <ul style="padding-left: 30px;">
            <li>Identifying Storage Devices</li>
            <li>SATA Drives</li>
            <li>SSDs</li>
            <li>SCSI Drives</li>
            <li>Overview RAID storage</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Understanding printers</h2>
        <ul style="padding-left: 30px;">
            <li>Understanding laser printers</li>
            <li>Understanding Inkjet Printers</li>
            <li>Understanding Thermal Printers</li>
            <li>Understanding Impact Printers</li>
            <li>Setting up and Installing a Printer</li>
            <li>Wireless printing</li>
            <li>Printer toner refilling</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Modern Hardware devices and gadgets</h2>
        <ul style="padding-left: 30px;">
            <li>Installing Various Types of Hardware Components</li>
            <li>Readers and scanners</li>
            <li>Projector</li>
            <li>CCTV and Surveillance system</li>
            <li>Computer Firmwares</li>
            <li>Assembling a PC</li>
            <li>UPS and Inverters</li>
            <li>Basic Electronics overview</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Overview of Laptop Hardware</h2>
        <ul style="padding-left: 30px;">
            <li>Laptop Inbuilt Hardware components - Speakers, Motherboards & CPU</li>
            <li>Laptop Internal Hardware-Memory & Optical Drives</li>
            <li>Laptop RAM and SSDs</li>
            <li>Laptop Components- Express Cards</li>
            <li>Laptop Components- Expansion Cards</li>
            <li>Servicing laptop hardware</li>
            <li>Tablets vs Laptops</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Troubleshooting IT Hardware</h2>
        <ul style="padding-left: 30px;">
            <li>Troubleshooting hardware devices</li>
            <li>Troubleshooting laptop components</li>
            <li>Troubleshooting Hard Drives</li>
            <li>Troubleshooting Printers</li>
            <li>Desktop chip level repairing basics</li>
            <li>Mobile repairing – Basics of mobile repairing</li>
            <li>Laptop chip level repairing basics</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Computer Safety and Operational Procedures</h2>
        <ul style="padding-left: 30px;">
            <li>Computer Safety Procedures</li>
            <li>Managing Computing Environments</li>
            <li>Managing Safety Hazards</li>
            <li>PC Hardware-Electrostatic Discharge Tools</li>
            <li>Computer and Laptop Cleaning Tools</li>
            <li>PC Hardware Diagnostic Tools</li>
            <li>Securing Computers</li>
            <li>Computer Hardware Recycling</li>
            <li>Communication and Professionalism</li>
        </ul>`,
        price: "6200",
        duration: "120 Hours",
        level: "Intermediate",
      },
      {
        url: "klic-network-support",
        name: "KLiC Network Support",
        icon: "../assets/courses/network.svg",
        content:
          "In simple words, IT Network Support Technician is the one who troubleshoots, monitors, maintains the Internet and Intranet connectivity also the learner can monitor and maintain the uptime of various types of Networking Devices such as Routers, Switches, and Hubs.",
        certification: `<ul>
        <li>KLiC courses are recognised by Yashwantrao Chavan Maharashtra Open University (YCMOU).</li>
        <li>MKCL provides certificate to the KLiC learner after his/her successful course completion.</li>
        <li>Yashwantrao Chavan Maharashtra Open University (YCMOU) provides mark sheet to successfully passed KLiC learners (Jurisdiction: Maharashtra).</li>
        </ul>`,
        objective:
          "The KLiC Network Support course trains learners to troubleshoot, monitor, and maintain internet and intranet connectivity, as well as networking devices like routers, switches, and hubs.",
        syllabus: `Basics of Networking, Network Cabling, Basics of IP addressing, Configuring DHCP for Small Network, Troubleshooting of Networks, Configuring Wi-Fi Network
        <br><br>
        <b>Tools</b>: IT helpdesk, Network monitoring tools, LAN testers, Command line tools, Open source subnet calculators, Network packet analyzers`,
        topics: `<h2 style="font-weight: bold;">Basics of Networking</h2>
        <ul style="padding-left: 30px;">
            <li>OSI Model</li>
            <li>TCP/IP Model</li>
            <li><a href="Reference_Link_1">Reference Link 1</a></li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Network Protocols</h2>
        <ul style="padding-left: 30px;">
            <li>Network Topology and Network Devices</li>
            <li>Reference Link</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Network Cabling</h2>
        <ul style="padding-left: 30px;">
            <li>Internet Connectivity Types</li>
            <li>Basics of IP addressing</li>
            <li>Classes of IP addresses</li>
            <li>Difference Between Static and Dynamic IPs</li>
            <li>Subnetting</li>
            <li>Configuring DHCP for Small Network</li>
            <li>Network Ports</li>
            <li>Overview of DNS</li>
            <li>WINS vs DNS</li>
            <li>MAC Address Formats</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Troubleshooting of Networks</h2>
        <ul style="padding-left: 30px;">
            <li>Network Troubleshooting Tools</li>
            <li>Configuring Wi-Fi Network</li>
            <li>Wireless Standards</li>
        </ul>`,
        price: "6200",
        duration: "120 Hours",
        level: "Intermediate",
      },
      {
        url: "klic-security-support",
        name: "KLiC Security Support",
        icon: "../assets/courses/security.svg",
        content: `Almost everyone around us type at the keyboard of computers or tablets and use their smart phones daily. Computing and communications technologies have become an integral part of our life and in the context of this technology-rich environment the term “cyber space” often arises.
        <br><br>
        KLiC Security Support course is designed for young aspirants who want to shape their careers in IT Security field.`,
        certification: `<ul>
        <li>KLiC courses are recognised by Yashwantrao Chavan Maharashtra Open University (YCMOU).</li>
        <li>MKCL provides certificate to the KLiC learner after his/her successful course completion.</li>
        <li>Yashwantrao Chavan Maharashtra Open University (YCMOU) provides mark sheet to successfully passed KLiC learners (Jurisdiction: Maharashtra).</li>
        </ul>`,
        objective: `The KLiC Security Support course provides comprehensive training in cybersecurity, covering topics such as network security, malware protection, ethical hacking, and server hardening, preparing learners for roles like Security Consultant, Information Security Analyst, and Ethical Hacker.`,
        syllabus:
          "Basics of Networking, Securing Personal computers and Operating Systems, Network Security Protocols and Technologies, Common Cyber Attacks, Ethical Hacking.",
        topics: `<h2 style="font-weight: bold;">Basics of Networking</h2>
        <ul style="padding-left: 30px;">
            <li>Overview of Computer Viruses</li>
            <li>Rootkits</li>
            <li>Botnets</li>
            <li>Ransomware</li>
            <li>Using Antivirus Software</li>
            <li>Adwares and Spywares</li>
            <li>Antispam Technologies</li>
            <li>Installing Free Antivirus and Scanning the PC</li>
            <li>Installing Free Antispyware and scanning PC for Adware and Spywares</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Securing Personal computers and Operating Systems</h2>
        <ul style="padding-left: 30px;">
            <li>Patch Management</li>
            <li>Patch Deployment and verification</li>
            <li>Installing and configuring Open Source Firewall</li>
            <li>Firewalls</li>
            <li>Proxy server</li>
            <li>Routers</li>
            <li>Configuring Windows Firewall</li>
            <li>Overview of IPS and IDS</li>
            <li>Network load balancers</li>
            <li>Securing Servers - Server Hardening</li>
            <li>Windows Server Hardening</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Network Security Protocols and Technologies</h2>
        <ul style="padding-left: 30px;">
            <li>WEP, WPA and WPA2</li>
            <li>MAC Address Filtering</li>
            <li>Authentication and Encryption</li>
            <li>Network Address Translation</li>
            <li>Secure Remote Access</li>
            <li>Secure Remote Access - Configuring VPN in Windows 7</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Common Cyber Attacks</h2>
        <ul style="padding-left: 30px;">
            <li>Phishing and Spoofing attacks</li>
            <li>Social Engineering</li>
            <li>Man in the Middle Attack</li>
            <li>Denial of Service Attack</li>
            <li>Using network packet capturing tool</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Ethical Hacking</h2>
        <ul style="padding-left: 30px;">
            <li>Foot printing</li>
            <li>Open Source Security Scanning tools like NMAP</li>
            <li>Using Kali Linux for exploring various Ethical Hacking Tools</li>
        </ul>
        `,
        price: "6200",
        duration: "120 Hours",
        level: "Intermediate",
      },
    ],
  },
  {
    category: "Programming",
    url: "programming",
    categoryIcon: "../assets/categories/programming.png",
    courses: [
      {
        url: "klic-c-programming",
        name: "KLiC C",
        icon: "../assets/courses/C.svg",
        content: `C is one of the most widely used and user friendly programming language. It is faster than any other programming language. The main features of C language are simple set of keywords, low-level access to memory and clean style.
        <br><br>
        <h2 style="font-weight: bold;">Advantages of Learning C Programming:</h2>
        <ul style="padding-left: 30px;">
            <li>You will be able to read and write codes for a large number of platforms, ranging from microcontrollers to advanced scientific systems.</li>
            <li>If you learn C, learning C++ becomes easier.</li>
        </ul>
        `,
        certification: `<ul>
        <li>KLiC courses are recognised by Yashwantrao Chavan Maharashtra Open University (YCMOU).</li>
        <li>MKCL provides certificate to the KLiC learner after his/her successful course completion.</li>
        <li>Yashwantrao Chavan Maharashtra Open University (YCMOU) provides mark sheet to successfully passed KLiC learners (Jurisdiction: Maharashtra).</li>
        </ul>`,
        objective:
          "The KLiC C Programming course aims to equip learners with a comprehensive understanding of the C programming language, enabling them to develop skills necessary for various roles in software development, system engineering, and related fields.",
        syllabus:
          "The Decision Control Structure, Loop Control Structure, Case Control Structure, Functions and Pointers, Data Types Revisited, The C Preprocessor, Arrays, Pupating on Strings, Structures, Console Input/Output, File Input/Output, More Issues in Input/Output, Operations on Bits, Miscellaneous Features, Under Windows 535, Windows Programming, Graphics under Windows, Interaction with Hardware, Under Linux, More Linux Programming, Memory Mapping, C Traps & Pitfalls.",
        topics: `<h2 style="font-weight: bold;">Getting Started - Brief Introduction</h2>
        <ul style="padding-left: 30px;">
            <li>Programming Language</li>
            <li>About C Programming</li>
            <li>C Character Set</li>
            <li>Constants, Variables & Keywords</li>
            <li>Constants in C</li>
            <li>Variables in C</li>
            <li>Writing a C Program</li>
            <li>Instructions and Assignments</li>
            <li>Basic Operators in C Programming</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">The Decision Control Structure</h2>
        <ul style="padding-left: 30px;">
            <li>Decisions Control Structure & the If Statement</li>
            <li>The if-else Statement</li>
            <li>Use of Logical Operators</li>
            <li>Different types of Operators</li>
            <li>Points to remember</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Loop Control Structure</h2>
        <ul style="padding-left: 30px;">
            <li>Loops and the While loop</li>
            <li>While Loop</li>
            <li>For Loop</li>
            <li>Operators in Loop</li>
            <li>The Odd Loop</li>
            <li>Break Statement</li>
            <li>Continue Statement</li>
            <li>Do-while loop</li>
            <li>Tips to remember</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Case Control Structure</h2>
        <ul style="padding-left: 30px;">
            <li>Decisions using switch</li>
            <li>The Tips and Traps</li>
            <li>Switch versus if-else Ladder</li>
            <li>The goto keyword</li>
        </ul>
        <br>
        <h2 style="font-weight: bold;">Functions and Pointers</h2>
        <ul style="padding-left: 30px;">
            <li>About Functions</li>
            <li>Passing Values between Functions</li>
            <li Scope Rule of Functions</li>
            <li>Calling Convention</li>
            <li>One Dicey Issue</li>
            <li>Advanced Features of Functions</li>
            <li>Function Declaration and Prototypes</li>
            <li>Call by Value or Call by Reference</li>
            <li>An Introduction to Pointers</li>
            <li>Pointer Notation</li>
            <li>Function Calls</li>
            <li>Basics of Call by value and call by reference</li>
            <li>Recursion</li>
            <li>Recursion and Stack</li>
            <li>Adding Functions to the Library</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Data Types Revisited</h2>
        <ul style="padding-left: 30px;">
            <li>Data type</li>
            <li>Integer number variables</li>
            <li>Integers, signed and unsigned</li>
            <li>Chars, signed and unsigned</li>
            <li>Floats and Doubles</li>
            <li>Issues related to Data types</li>
            <li>Storage Classes in C</li>
            <li>Automatic Storage Class</li>
            <li>Register Storage Class</li>
            <li>Static Storage Class</li>
            <li>External Storage Class</li>
            <li>To study the Ground rules for the Storage Class</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">The C Preprocessor</h2>
        <ul style="padding-left: 30px;">
            <li>Features of C Preprocessor</li>
            <li>Preprocessor and Macro Directives</li>
            <li>Macros with Arguments and Macros versus Functions</li>
            <li>Various Directives</li>
        </ul>
        <br>
        <h2 style="font-weight: bold;">Arrays</h2>
      <ul style="padding-left: 30px;">
          <li>What are Arrays?</li>
          <li>Usage of Arrays</li>
          <li>Pointers and Arrays</li>
          <li>Passing an Entire Array to a Function</li>
          <li>Two Dimensional Arrays</li>
          <li>Initializing a 2-Dimensional Array</li>
          <li>Memory Map of a 2-Dimensional Array</li>
          <li>Pointers and 2-Dimensional Arrays</li>
          <li>Pointer to an Array</li>
          <li>Passing 2-D array to a Function</li>
          <li>Array of Pointers</li>
          <li>Three Dimensional Array</li>
      </ul>
      <br/>

      <h2 style="font-weight: bold;">Pupating on Strings</h2>
      <ul style="padding-left: 30px;">
          <li>What are Strings?</li>
          <li>Pointers and Strings</li>
          <li>Standard Library String Functions</li>
          <li>Two-Dimensional Array of Characters</li>
          <li>Array of Pointers to Strings</li>
          <li>Limitations of Array of Pointers to Strings</li>
      </ul>
      <br/>

      <h2 style="font-weight: bold;">Structures</h2>
      <ul style="padding-left: 30px;">
          <li>Why Use Structures?</li>
          <li>Declaring a Structure</li>
          <li>Accessing Structure Elements</li>
          <li>Array of Structures</li>
          <li>Additional Features of Structures</li>
          <li>Uses of Structures</li>
      </ul>
      <br/>

      <h2 style="font-weight: bold;">Console Input/Output</h2>
      <ul style="padding-left: 30px;">
          <li>Types of I/O</li>
          <li>Formatted Console I/O Functions</li>
          <li>sprintf( ) and sscanf( ) Functions</li>
          <li>Unformatted Console I/O Functions</li>
      </ul>
    <br/>
    <h2 style="font-weight: bold;">File Input/Output</h2>
    <ul style="padding-left: 30px;">
        <li>Data Organization</li>
        <li>File Operations</li>
        <li>Opening a File</li>
        <li>Reading from a File</li>
        <li>Trouble in Opening a File</li>
        <li>Closing the File</li>
        <li>Counting Characters, Tabs, Spaces</li>
        <li>A File-copy Program</li>
        <li>Writing to a File</li>
        <li>File Opening Modes</li>
        <li>String (line) I/O in Files</li>
        <li>The Awkward Newline</li>
        <li>Record I/O inFiles</li>
        <li>Text Files and Binary Files</li>
        <li>Record I/O Revisited</li>
        <li>Database Management</li>
        <li>Low Level Disk I/O</li>
        <li>A Low Level File-copy Program</li>
        <li>I/O Under Windows</li>
    </ul>
    <br/>

    <h2 style="font-weight: bold;">More Issues in Input/Output</h2>
    <ul style="padding-left: 30px;">
        <li>Using argc and argv</li>
        <li>Detecting Errors in Reading/Writing</li>
        <li>Explanation</li>
        <li>Standard I/O Devices</li>
        <li>I/O Redirection</li>
        <li>Redirecting the Output</li>
        <li>Redirecting the Input & Both Ways at Once</li>
    </ul>
    <br/>

    <h2 style="font-weight: bold;">Operations on Bits</h2>
    <ul style="padding-left: 30px;">
        <li>Binary System & Bitwise Operators</li>
        <li>Bitwise AND Operator</li>
        <li>Bitwise OR Operator</li>
        <li>Bitwise XOR Operator</li>
        <li>One's Complement Operator</li>
        <li>Shift Operator</li>
        <li>The showbits( ) Function</li>
    </ul>
    <br/>

    <h2 style="font-weight: bold;">Miscellaneous Features</h2>
    <ul style="padding-left: 30px;">
        <li>Enumerated Data Type and its uses</li>
        <li>Understanding with a Program</li>
        <li>Renaming Data Types with typedef</li>
        <li>Typecasting</li>
        <li>Bit Fields</li>
        <li>Pointers to Functions</li>
        <li>Functions Returning Pointers</li>
        <li>Functions with Variable Number of Arguments</li>
        <li>Unions & Union of Structure</li>
    </ul>
    <br>
    <h2 style="font-weight: bold;">Under Windows</h2>
    <ul style="padding-left: 30px;">
        <li>Uses of Windows</li>
        <li>Integers</li>
        <li>The Use of typedef</li>
        <li>Pointers in the 32-bit World</li>
        <li>Memory Management & Device Access</li>
        <li>DOS Programming Model</li>
        <li>Windows Programming Model</li>
        <li>Event Driven Model & Windows programming</li>
        <li>The First Windows Program</li>
        <li>Hungarian Notation</li>
    </ul>
    <br/>

    <h2 style="font-weight: bold;">Windows Programming</h2>
    <ul style="padding-left: 30px;">
        <li>The Role of a Message Box</li>
        <li>Here Comes the windows</li>
        <li>More Windows</li>
        <li>A Real-World Window</li>
        <li>Creation and Displaying of Window</li>
        <li>Interaction with Window</li>
        <li>Reacting to Messages</li>
        <li>Program Instances</li>
    </ul>
    <br/>

    <h2 style="font-weight: bold;">Graphics under Windows</h2>
    <ul style="padding-left: 30px;">
        <li>Graphics fundamentals</li>
        <li>Device Independent Drawing</li>
        <li>Hello Windows program</li>
        <li>Drawing Shapes</li>
        <li>Types of Pens</li>
        <li>Types of Brushes</li>
        <li>Code and Resources</li>
        <li>Freehand Drawing, the Paintbrush Style</li>
        <li>Capturing the Mouse</li>
        <li>Device Context, a Closer Look</li>
        <li>Displaying a Bitmap</li>
        <li>Animation at Work</li>
        <li>WM_CREATE and On Create( )</li>
        <li>WM_TIMER and On Timer( )</li>
    </ul>
    <br/>

    <h2 style="font-weight: bold;">Interaction with Hardware</h2>
    <ul style="padding-left: 30px;">
        <li>Hardware Interaction</li>
        <li>Hardware Interaction, DOS Perspective</li>
        <li>Hardware Interaction, Windows Perspective</li>
        <li>Communication with Storage Devices</li>
        <li>The Read Sector( ) Function</li>
        <li>Accessing Other Storage Devices</li>
        <li>Communication with Keyboard</li>
        <li>Dynamic Linking</li>
        <li>Windows Hooks</li>
        <li>Caps Locked, Permanently</li>
        <li>Mangling Keys</li>
        <li>Key Logger</li>
    </ul>
    <br/>

    <h2 style="font-weight: bold;">Under Linux</h2>
    <ul style="padding-left: 30px;">
        <li>What is Linux</li>
        <li>C Programming Under Linux</li>
        <li>The ‘Hello Linux’ Program</li>
        <li>Processes</li>
        <li>Parent and Child Processes</li>
        <li>More Processes</li>
        <li>Zombies and Orphans</li>
    </ul>
    <br>
    <h2 style="font-weight: bold;">More Linux Programming</h2>
    <ul style="padding-left: 30px;">
        <li>Communication using Signals</li>
        <li>Handling Multiple Signals</li>
        <li>Registering a Common Handler</li>
        <li>Blocking Signals</li>
        <li>Event Driven Programming</li>
    </ul>
    <br/>

    <h2 style="font-weight: bold;">Memory Mapping</h2>
    <ul style="padding-left: 30px;">
        <li>Introduction to Memory Map</li>
        <li>Memory Organization</li>
        <li>Segmentation</li>
        <li>Loading OS & Booting Process</li>
        <li>The resident and transient memory area</li>
        <li>Program memory area at run time</li>
        <li>Memory representation of data & function objects</li>
    </ul>
    <br/>

    <h2 style="font-weight: bold;">C Traps & Pitfalls</h2>
    <ul style="padding-left: 30px;">
        <li>Introduction</li>
        <li>Lexical pitfalls</li>
        <li>Exceptions, String & characters</li>
        <li>Understanding Declaration</li>
        <li>Exceptions in Operators' precedence</li>
        <li>Use of Semicolons</li>
        <li>The Switch statement</li>
        <li>Calling functions</li>
        <li>The Dangling else problem</li>
        <li>Linkages</li>
        <li>External Types</li>
        <li>Expression evaluation sequence</li>
        <li>Issues related to actual parameters</li>
        <li>Eshew Synecdoche</li>
        <li>Library Function</li>
        <li>Preprocessor</li>
        <li>Portability pitfalls</li>
        <li>Signed & Unsigned characters</li>
        <li>Random numbers</li>
        <li>Portability`,
        price: "6200",
        duration: "120 Hours",
        level: "Intermediate",
      },
      {
        url: "klic-c++-programming",
        name: "KLiC C++",
        icon: "../assets/courses/C++.svg",
        content: `C++ has imperative, object-oriented features. It is general-purpose, case sensitive and free-form programming language. It is widely used by the programmers or developers in application domain. It supports object-oriented programming including its features like Inheritance, Polymorphism, Encapsulation, Abstraction etc. <br> <br>
        <p>After completing this course, the student will be able to perform the following roles:</p>
        <h2 style="font-weight: bold;">Career Opportunities (Industry wise):</h2>
        <ul style="padding-left: 30px;">
            <li>Object Oriented Programming Analyst</li>
            <li>Programmer</li>
            <li>Embedded Programmer</li>
            <li>System Engineer</li>
            <li>System S/W Engineer</li>
            <li>Network Security Engineer</li>
            <li>Game Programmer</li>
            <li>Windows UI Programmer</li>
            <li>AI - Artificial Intelligence Programmer</li>
            <li>System Analyst</li>
            <li>Simulation Developer</li>
        </ul>`,
        certification: `<ul>
        <li>KLiC courses are recognised by Yashwantrao Chavan Maharashtra Open University (YCMOU).</li>
        <li>MKCL provides certificate to the KLiC learner after his/her successful course completion.</li>
        <li>Yashwantrao Chavan Maharashtra Open University (YCMOU) provides mark sheet to successfully passed KLiC learners (Jurisdiction: Maharashtra).</li>
        </ul>`,
        objective:
          "Master the fundamentals and advanced concepts of C++ programming language, including Object-Oriented Programming (OOP), data structures, inheritance, templates, exception handling, and more, through comprehensive instruction and hands-on exercises.",
        syllabus:
          "Introduction to OOP, Grad Function Prototypes, Functions, Classes in C++, The C++ Free Store, Miscellaneous Class Issue, Data Structures through C++, Inheritance, Virtual Functions, Input / Output in C++, Advanced Features, Templates, Exception Handling, Case Studies.",
        topics: `<h2 style="font-weight: bold;">Introduction to OOP</h2>
        <ul style="padding-left: 30px;">
            <li>The Beginning</li>
            <li>Structured Programming</li>
            <li>Object Oriented Programming</li>
            <li>Characteristics of Object Oriented Programming</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Grad Function Prototypes</h2>
        <ul style="padding-left: 30px;">
            <li>Comments</li>
            <li>Flexible Declarations</li>
            <li>Structure, union and enum Syntax</li>
            <li>Anonymous unions and enums</li>
            <li>Typecasting</li>
            <li>Void Pointers</li>
            <li>The Operator</li>
            <li>References</li>
            <li>The Const Qualifier</li>
            <li>Constructors for Intrinsic Data Types</li>
            <li>The bool Data Typecasting to C++</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Functions</h2>
        <ul style="padding-left: 30px;">
            <li>Function Prototypes</li>
            <li>Function Overloading</li>
            <li>Default Arguments in Functions</li>
            <li>Operator Overloading</li>
            <li>Inline Functions</li>
            <li>Static, virtual and friend Functions</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Classes in C++</h2>
        <ul style="padding-left: 30px;">
            <li>Classes and Constructors</li>
            <li>Destructors</li>
            <li>A Complex Class</li>
            <li>Overloaded Operators Revisited</li>
            <li>The Pointer</li>
            <li>Overloading Unary Operators</li>
            <li>Function Definition Outside The Class</li>
            <li>New and delete operators</li>
            <li>Using new and delete</li>
            <li>Malloc( )/free( ) versus new/delete</li>
            <li>The Matrix Class</li>
            <li>Classes, Objects, and Memory</li>
            <li>Structures and Classes</li>
        </ul>
        <br/>
        <h2 style="font-weight: bold;">The C++ Free Store</h2>
        <ul style="padding-left: 30px;">
            <li>Free Store Exhaustion</li>
            <li>Custom new and delete operators</li>
            <li>Overloading new/delete in Classes</li>
            <li>Understanding the sequence</li>
            <li>Construction at Predetermined Location</li>
            <li>One Last Issue</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Miscellaneous Class Issue</h2>
        <ul style="padding-left: 30px;">
            <li>Static Class Data</li>
            <li>Static Member Functions</li>
            <li>Const and Classes</li>
            <li>Overloaded Assignment Operator, Copy Constructor</li>
            <li>Data Conversion</li>
            <li>Data Conversion between Object of Different Classes</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Data Structures through C++</h2>
        <ul style="padding-left: 30px;">
            <li>Stacks and Queues</li>
            <li>The Linked List</li>
            <li>Stacks and Queues Revisited</li>
            <li>Trees</li>
            <li>Binary Trees</li>
            <li>Traversal of a Binary Tree</li>
            <li>Deletion from a Binary Tree</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Inheritance</h2>
        <ul style="padding-left: 30px;">
            <li>More Inheritance</li>
            <li>Some More Inheritance</li>
            <li>Multiple Levels of Inheritance</li>
            <li>Multiple Inheritance</li>
            <li>Constructors in Multiple Inheritance</li>
            <li>A Word of Caution</li>
            <li>Private Inheritance</li>
            <li>Protected Inheritance</li>
            <li>Uninherited Functions</li>
            <li>Incremental Development</li>
        </ul>
        <br/>
        <h2 style="font-weight: bold;">Virtual Functions</h2>
        <ul style="padding-left: 30px;">
            <li>Pure Virtual Functions</li>
            <li>Virtual Functions under the Hood</li>
            <li>Why virtual Functions?</li>
            <li>Virtual Functions in Derived Classes</li>
            <li>Object Slicing</li>
            <li>Virtual Functions and Constructors</li>
            <li>Destructors and virtual Destructors</li>
            <li>Virtual Base Classes</li>
            <li>Putting it All Together</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Input / Output in C++</h2>
        <ul style="padding-left: 30px;">
            <li>The iostream Library</li>
            <li>The ios Class</li>
            <li>Manipulators</li>
            <li>Creating Our Own Manipulators</li>
            <li>User-defined Manipulators with Arguments</li>
            <li>Come GUI and…</li>
            <li>The istream Class</li>
            <li>The ostream Class</li>
            <li>The iostream Class</li>
            <li>The with assign Classes</li>
            <li>Predefined Stream Objects</li>
            <li>Outputting Strings</li>
            <li>A Brief Review</li>
            <li>File I/O with Streams</li>
            <li>A Better way</li>
            <li>A File copy Program</li>
            <li>File Opening Modes</li>
            <li>Binary I/O</li>
            <li>Elementary Database Management</li>
            <li>Class That Read/Write Themselves</li>
            <li>Errors during I/O</li>
            <li>File copy Program Revisited</li>
            <li>Overloading</li>
            <li>Str streams</li>
            <li>Automatic Storage Allocation</li>
            <li>Sending Output to Printer</li>
        </ul>
        <br/>
        <h2 style="font-weight: bold;">Advanced Features</h2>
        <ul style="padding-left: 30px;">
            <li>Classes Within Classes</li>
            <li>Friend Functions</li>
            <li>Overloading AND</li>
            <li>One More Use Of friend Function</li>
            <li>Friend Classes</li>
            <li>A Word of Caution</li>
            <li>Smart Pointers</li>
            <li>More Smart Pointers</li>
            <li>Pointers to Members</li>
            <li>The explicit Keyword</li>
            <li>The mutable Keyword</li>
            <li>Namespaces</li>
            <li>Using A Namespace</li>
            <li>RTTI</li>
            <li>When to Use RTTI</li>
            <li>Typecasting in C++</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Templates</h2>
        <ul style="padding-left: 30px;">
            <li>Function Templates</li>
            <li>A Template Based Quick Sort</li>
            <li>Class Templates</li>
            <li>A Linked List Class Template</li>
            <li>Tips about List Class Template</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Exception Handling</h2>
        <ul style="padding-left: 30px;">
            <li>Checking Function Return Value</li>
            <li>setjmp( ) and longjmp( )</li>
            <li>Exception Handling in C++</li>
            <li>Exception with Arguments</li>
        </ul>
        <br/>

        <h2 style="font-weight: bold;">Case Studies</h2>
        <ul style="padding-left: 30px;">
            <li>Tic Tac Toe Game</li>
            <li>Student Management System</li>
            <li>Student Attendance Management System</li>
            <li>Event Management System</li>
            <li>Hangman Game</li>
            <li>Employee Leave Management System</li>
            <li>Furniture Business System</li>
            <li>Society Management System</li>
        </ul>`,
        price: "6200",
        duration: "120 Hours",
        level: "Intermediate",
      },
      {
        url: "klic-java",
        name: "KLiC Java",
        icon: "../assets/courses/java.svg",
        content: `Take your first step towards a career in software development with this course on Java Programming - one of the most in-demand programming languages.
        <br><br>
        <ul style="padding-left: 30px;">
            <li> This course will help you learn the core programming concepts and make you ready to write programs to solve complex problems.</li>
            <li> This course includes hands-on practice and will give you a solid knowledge of the Java language. After completing this course, you will be able to identify Java’s benefits, understand the code behind various websites and start programming in Java.</li>            
        </ul>`,
        certification: `<ul>
        <li>KLiC courses are recognised by Yashwantrao Chavan Maharashtra Open University (YCMOU).</li>
        <li>MKCL provides certificate to the KLiC learner after his/her successful course completion.</li>
        <li>Yashwantrao Chavan Maharashtra Open University (YCMOU) provides mark sheet to successfully passed KLiC learners (Jurisdiction: Maharashtra).</li>
        </ul>`,
        objective:
          "This course aims to provide a foundational understanding of Java programming, covering core concepts and practical application. By the end of the course, students will be able to write Java programs, understand object-oriented principles, and apply Java in web development.",
        syllabus: `<h2 style="font-weight: bold;">The syllabus covers the following skills:</h2>
        <ul style="padding-left: 30px;">
            <li>Introduction to Java</li>
            <li>Core Java Concepts</li>
            <li>Control Structures and Loops</li>
            <li>Arrays, Searching, and Sorting</li>
            <li>Methods and Recursion</li>
            <li>Object-Oriented Programming</li>
            <li>Exception Handling and Java Collections</li>
            <li>Multithreading and File Handling</li>
            <li>Introduction to Java Applets and Swings</li>
            <li>Introduction to Web Development with Java</li>
            <li>Advanced Java Concepts</li>
            <li>Project Work and Practical Application</li>
        </ul>`,
        topics: `<h2 style="font-weight: bold;">Introduction to Java</h2>
        <ul style="padding-left: 30px;">
            <li>Why you should learn Java?</li>
            <li>Introduction to programming languages</li>
            <li>Installing Java Development Kit (JDK)</li>
            <li>JDK, JRE and JVM</li>
            <li>Importance of Java in the context of Internet</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Features of Java</h2>
        <ul style="padding-left: 30px;">
            <li>Features of Java</li>
            <li>Object Oriented Programming Paradigm (OOP)</li>
            <li>Relationship between classes and objects</li>
            <li>Features of OOPs - Encapsulation, Inheritance, Abstraction and Polymorphism</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Data Types, Variables, Literals</h2>
        <ul style="padding-left: 30px;">
            <li>Data types in Java - Integers, floating-point, characters, boolean</li>
            <li>Concept of variables, their declarations</li>
            <li>Literals - Integer, floating-point, character, boolean, strings</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Let's dive into the Java coding</h2>
        <ul style="padding-left: 30px;">
            <li>How to Write java code, compile and execute?</li>
            <li>First Java Program using Notepad</li>
            <li>Explanation of important terms like main(), public, void, static</li>
            <li>First Java Program using Netbeans</li>
            <li>Using Command Line arguments in Java program written in Notepad</li>
            <li>Using Command Line arguments in Java program written in Netbeans</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Important options of Netbeans IDE</h2>
        <ul style="padding-left: 30px;">
            <li>Introduction to IDEs</li>
            <li>Components of Netbeans</li>
            <li>Creating a project in Netbeans</li>
            <li>Exporting & importing projects</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Reading the values of various data types</h2>
        <ul style="padding-left: 30px;">
            <li>Reading values from keyboard using Scanner class object</li>
            <li>Tricks to prevent the skipping of String input value</li>
            <li>Implicit type casting</li>
            <li>Explicit type casting</li>
        </ul>
        <br/>
        <h2 style="font-weight: bold;">Operators</h2>
            <ul style="padding-left: 30px;">
                <li>Operators in Java</li>
                <li>Precedence and associativity of operators</li>
                <li>Examples of precedence and associativity</li>
                <li>Programs illustrating usage of arithmetic operators, relational operators, logical operators, unary operators, ternary operator</li>
                <li>Compound assignment operators</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Programming Building Blocks (Control Structures)</h2>
            <ul style="padding-left: 30px;">
                <li>Programming Construct Sequence</li>
                <li>if statement</li>
                <li>if else if ladder</li>
                <li>Nested if</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Switch Case Construct</h2>
            <ul style="padding-left: 30px;">
                <li>Overview of switch case construct</li>
                <li>Difference between if and switch case</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Iteration (Looping)</h2>
            <ul style="padding-left: 30px;">
                <li>Basics of loops and while loop</li>
                <li>while loop pre-tested property</li>
                <li>Basics of do..while loop</li>
                <li>while loop post-tested property</li>
                <li>Basics of for loop</li>
                <li>for loop pre-tested property</li>
                <li>More features of for loop</li>
                <li>Infinite loops</li>
                <li>Using break statement</li>
                <li>Using continue statement</li>
                <li>Programs to find - Factorial, Reverse a number, Palindrome number, Armstrong number, Prime number, Biggest among n numbers</li>
                <li>Using for each format of the for loop</li>
                <li>break and continue statements with examples</li>
                <li>Nested while loop with examples</li>
                <li>Nested for loop with examples</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Honing the Programming Skills Using Loops</h2>
            <ul style="padding-left: 30px;">
                <li>Programs - using loops</li>
                <li>Programs - using nested loops</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">The Concept of Arrays</h2>
            <ul style="padding-left: 30px;">
                <li>The basic concept of arrays</li>
                <li>Declaring an array dynamically using two methods</li>
                <li>Traversal of array elements</li>
                <li>Initializing array elements</li>
            </ul>
            <br/>
            <h2 style="font-weight: bold;">Searching Techniques</h2>
            <ul style="padding-left: 30px;">
                <li>Linear Search Logic and Algorithm</li>
                <li>Logic of Binary Search</li>
                <li>Algorithm of Binary search</li>
                <li>Programs - Linear Search and Binary search</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Sorting Techniques</h2>
            <ul style="padding-left: 30px;">
                <li>Logic of Bubble sort</li>
                <li>Algorithm of Bubble sort</li>
                <li>Logic of Selection sort</li>
                <li>Algorithm of Selection sort</li>
                <li>Programs - Bubble sort and Selection sort</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Double Dimensional (2-D) Arrays</h2>
            <ul style="padding-left: 30px;">
                <li>Basics of double dimensional arrays</li>
                <li>Double dimensional arrays with different number of columns</li>
                <li>Matrix addition - logic and program</li>
                <li>Logic of matrix multiplication</li>
                <li>Algorithm and program of matrix multiplication</li>
                <li>Using sort method of Arrays class</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Functions (Methods)</h2>
            <ul style="padding-left: 30px;">
                <li>Basics of functions</li>
                <li>Developing Functions - with a practical approach</li>
                <li>variables in functions</li>
                <li>Some facts about return statement</li>
                <li>Passing an array to a function</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Recursion</h2>
            <ul style="padding-left: 30px;">
                <li>Recursion (Recursive functions)</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Java Coding for Classes and Objects</h2>
            <ul style="padding-left: 30px;">
                <li>Practical coding of classes and objects</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Method Overloading</h2>
            <ul style="padding-left: 30px;">
                <li>Method Overloading (Compile-time polymorphism)</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Constructors</h2>
            <ul style="padding-left: 30px;">
                <li>Constructors in Java</li>
                <li>Default Constructors</li>
                <li>Constructor overloading</li>
                <li>this keyword</li>
            </ul>
            <br/>
            <h2 style="font-weight: bold;">Getters and Setters</h2>
            <ul style="padding-left: 30px;">
                <li>Basics of getters and setters</li>
                <li>Automatic creation of setter getter code</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Static Members of a Class</h2>
            <ul style="padding-left: 30px;">
                <li>Static members of a class</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Packages in Java</h2>
            <ul style="padding-left: 30px;">
                <li>Basics of Packages</li>
                <li>Access specifiers (Visibility modes) in Java</li>
                <li>Understanding default and private members practically</li>
                <li>Package classes in different programs</li>
                <li>Working in different packages</li>
                <li>Usage of import keyword of Java</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Method Signatures</h2>
            <ul style="padding-left: 30px;">
                <li>Method signatures</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Abstract Classes</h2>
            <ul style="padding-left: 30px;">
                <li>Creating abstract classes</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Interfaces in Java</h2>
            <ul style="padding-left: 30px;">
                <li>The basics of interfaces</li>
                <li>Practical coding of interfaces</li>
                <li>An interface extending another interface</li>
            </ul>
            <br/>
            <h2 style="font-weight: bold;">Inheritance</h2>
            <ul style="padding-left: 30px;">
                <li>Introduction to Inheritance</li>
                <li>Syntax of applying Inheritance and Method overriding</li>
                <li>Multi-Level inheritance</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Behaviour of Constructors in Inheritance</h2>
            <ul style="padding-left: 30px;">
                <li>Behaviour of constructors in Inheritance</li>
                <li>Practical examples of constructors with and without arguments in inheritance</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Using final Keyword</h2>
            <ul style="padding-left: 30px;">
                <li>Final classes</li>
                <li>Final methods</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Storing Reference of Sub-class Object into Super-class Reference Variable</h2>
            <ul style="padding-left: 30px;">
                <li>Assigning reference of sub-class object into super class reference variable</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Abstract Methods</h2>
            <ul style="padding-left: 30px;">
                <li>Abstract methods</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Polymorphism</h2>
            <ul style="padding-left: 30px;">
                <li>Basics of Polymorphism</li>
                <li>Practical implementation of Polymorphism</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Exception Handling</h2>
            <ul style="padding-left: 30px;">
                <li>Basics of exception handling</li>
                <li>Uncaught exceptions</li>
                <li>Catching exceptions using try .... catch blocks</li>
                <li>Multiple catch blocks</li>
                <li>Calling a method from within try block</li>
                <li>Using super class Exception</li>
                <li>Handling NullPointerException</li>
                <li>Handling NumberFormatException</li>
                <li>throw: throwing an exception manually</li>
                <li>throws: handling checked exceptions</li>
                <li>Nested try block</li>
            </ul>
            <br/>
            <h2 style="font-weight: bold;">Java Collection Framework</h2>
            <ul style="padding-left: 30px;">
                <li>Basics of Collections and ArrayList traversal using Iterator interface</li>
                <li>Traversing ArrayList elements using for each loop</li>
                <li>Storing objects of user-defined classes in collection</li>
                <li>Using toArray() method of ArrayList class</li>
                <li>LinkedList Collection</li>
                <li>ListIterator interface - Traversing collection elements in both directions</li>
                <li>Using sort method of Collections class</li>
                <li>Sorting user-defined objects</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Strings in Java</h2>
            <ul style="padding-left: 30px;">
                <li>Basics of strings in Java</li>
                <li>Important String class methods</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Multithreading</h2>
            <ul style="padding-left: 30px;">
                <li>Basics of Multithreading</li>
                <li>Implementing Runnable interface for Multithreading</li>
                <li>Extending Thread class for Multithreading</li>
                <li>Thread Names, Priorities, parent thread</li>
                <li>Method main as a thread</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Anonymous Inner Classes & LAMBDA Expressions</h2>
            <ul style="padding-left: 30px;">
                <li>Anonymous inner classes</li>
                <li>LAMBDA expressions</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Basics of Databases & MySQL</h2>
            <ul style="padding-left: 30px;">
                <li>Introduction to DBMS & Databases</li>
                <li>Creating a Database and Table using MySQL Workbench GUI</li>
                <li>Creating a Database and Table at command prompt</li>
                <li>Issuing SQL statements on Command prompt</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">JDBC (Java DataBase Connectivity)</h2>
            <ul style="padding-left: 30px;">
                <li>Basics of JDBC</li>
                <li>Architecture of JDBC</li>
                <li>Coding JDBC program for SQL statement Select, Insert, Delete, Update</li>
            </ul>
            <br/>
            <h2 style="font-weight: bold;">Streams in Java</h2>
            <ul style="padding-left: 30px;">
                <li>Basics of streams</li>
                <li>Using BufferedReader and InputStreamReader classes</li>
                <li>Reading from a file</li>
                <li>Writing on a file</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Java Applets</h2>
            <ul style="padding-left: 30px;">
                <li>Basics of Java Applets</li>
                <li>Writing your first applet and understanding life cycle</li>
                <li>Working with colors and fonts</li>
                <li>Passing parameters to an applet</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Applet Programming in NetBeans</h2>
            <ul style="padding-left: 30px;">
                <li>First Applet in Netbeans</li>
                <li>Drawing figures</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Event Handling in Applets</h2>
            <ul style="padding-left: 30px;">
                <li>Basics of event handling</li>
                <li>Handling mouse events</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">AWT Controls</h2>
            <ul style="padding-left: 30px;">
                <li>AWT Controls</li>
                <li>Using Fonts and Colors in AWT Controls</li>
                <li>Positioning AWT Controls by setBounds() method</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Event Handling of AWT Controls</h2>
            <ul style="padding-left: 30px;">
                <li>Handling Button events</li>
                <li>Handling Checkbox events</li>
                <li>Handling Radio button events</li>
                <li>Handling Scrollbar events</li>
                <li>Handling List events</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Applet Form Projects - Using Palette Box</h2>
            <ul style="padding-left: 30px;">
                <li>Example of an Applet form project</li>
                <li>Handling Button events through Applet forms</li>
                <li>Handling Checkbox events through Applet forms</li>
                <li>Handling Choice events through Applet forms</li>
            </ul>
            <br/>
            <h2 style="font-weight: bold;">Frame Projects</h2>
            <ul style="padding-left: 30px;">
                <li>Differences between Applets and Frames</li>
                <li>Example of a Frame</li>
                <li>Getting more comfortable - Example of a Frame</li>
                <li>Changing the Title and Size of a Frame window</li>
                <li>Changing the background color of a Frame window</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Java Swings</h2>
            <ul style="padding-left: 30px;">
                <li>Basics of Swings & differences from Applets</li>
                <li>Using setBounds() and setFonts() with swing controls</li>
                <li>Using JCheckBox class object</li>
                <li>Creating a tree structure using JTree class object</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Event Handling in Java Swings</h2>
            <ul style="padding-left: 30px;">
                <li>Handling events of JButton objects</li>
                <li>Handling events of JCheckBox object</li>
                <li>Handling events of JRadioButton objects</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Swing Programs in Netbeans - Using JApplet project</h2>
            <ul style="padding-left: 30px;">
                <li>Swing Program in Netbeans</li>
                <li>Using Swing controls in JApplet project</li>
                <li>Event Handling in Japplet</li>
                <li>Using JOptionPane class for message dialog boxes</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Working with JApplet Form Projects</h2>
            <ul style="padding-left: 30px;">
                <li>JApplet Form swing</li>
                <li>More stuff on JApplet Form swings</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Working with JFrame Form Projects</h2>
            <ul style="padding-left: 30px;">
                <li>Program of JFrame Project</li>
                <li>Working with DateChooser</li>
                <li>Creating a MenuBar - Menu objects, MenuItem Objets</li>
                <li>Handling Events of MenuItems</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Introduction to Web Development</h2>
            <ul style="padding-left: 30px;">
                <li>Requirements</li>
                <li>Introduction to web applications</li>
                <li>Installing Tomcat server</li>
                <li>Installing Eclipse IDE</li>
                <li>Connecting Eclipse to Tomcat</li>
            </ul>
            <br/>
            <h2 style="font-weight: bold;">Let's dive into JSP programming</h2>
            <ul style="padding-left: 30px;">
                <li>The very first program of JSP</li>
                <li>JSP Tags</li>
                <li>Implicit objects in JSP</li>
                <li>Request implicit object of JSP</li>
                <li>JSP tags practical examples</li>
                <li>Using import</li>
                <li>Including pages into a JSP page</li>
                <li>Difference between jsp:forward and jsp:include</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Using Java classes in JSP</h2>
            <ul style="padding-left: 30px;">
                <li>Creating a class & using it in JSP program</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Interacting with HTML forms</h2>
            <ul style="padding-left: 30px;">
                <li>Reading data from Textboxes</li>
                <li>Alternative to getParameter() method</li>
                <li>Reading data from dropdown list</li>
                <li>Reading data from radio buttons (option buttons)</li>
                <li>Reading data from a single checkbox</li>
                <li>Reading data from a group of checkboxes</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Sessions in JSP</h2>
            <ul style="padding-left: 30px;">
                <li>Basics of session management</li>
                <li>Storing & retrieving from a session object</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Cookies in JSP</h2>
            <ul style="padding-left: 30px;">
                <li>Concepts of cookies</li>
                <li>Storing & retrieving cookies</li>
                <li>Working with multiple cookies</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">JSP standard tag library (JSTL)</h2>
            <ul style="padding-left: 30px;">
                <li>Basic concepts of JSTL</li>
                <li>Using & JSTL tags</li>
                <li>Using JSTL tag</li>
                <li>Using JSTL tag</li>
                <li>Using , & JSTL tags</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">JDBC in JSP - Learning the basics</h2>
            <ul style="padding-left: 30px;">
                <li>Understanding DBMS, databases & tables etc.</li>
                <li>Getting familiar with JDBC API</li>
                <li>Steps for JDBC coding</li>
                <li>Downloading XAMPP for MySQL</li>
                <li>Alert! Listen before you proceed</li>
                <li>Learning SQL</li>
                <li>How to create a database and tables using GUI in XAMPP</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">JDBC in JSP - coding part</h2>
            <ul style="padding-left: 30px;">
                <li>Importing MySQL connector</li>
                <li>Executing SQL command select in JDBC code</li>
                <li>Displaying the rows in HTML table</li>
                <li>Executing SQL command insert in JDBC code</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Introduction to Servlets</h2>
            <ul style="padding-left: 30px;">
                <li>Basics of servlets</li>
                <li>Difference between servlets and JSP</li>
                <li>Introduction to important classes and interfaces</li>
                <li>First servlet Program - Reading from an HTML form</li>
                <li>Creating an HTML form within a servlet</li>
                <li>Dealing with numbers in servlets</li>
            </ul>
            <br/>
            <h2 style="font-weight: bold;">Interaction between multiple pages</h2>
            <ul style="padding-left: 30px;">
                <li>Introduction to RequestDispatcher interface</li>
                <li>Coding with RequestDispatcher interface</li>
                <li>Introduction to SendRedirect method and difference from forward method</li>
                <li>Coding with sendRedirect method</li>
                <li>Working with hidden fields</li>
                <li>URL rewriting</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Cookies in Servlets</h2>
            <ul style="padding-left: 30px;">
                <li>Introduction to cookies</li>
                <li>Writing and reading cookies</li>
                <li>Login and logout with cookies example</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Sessions in Servlets</h2>
            <ul style="padding-left: 30px;">
                <li>Introduction to sessions</li>
                <li>Writing and reading session objects</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Context parameters and Config parameters</h2>
            <ul style="padding-left: 30px;">
                <li>Introduction to ServletContext and ServletConfig interfaces</li>
                <li>Storing and retrieving a single context parameter</li>
                <li>Storing & retrieving multiple context parameters</li>
                <li>Storing & retrieving a single config parameter</li>
                <li>Storing & retrieving multiple config parameters</li>
            </ul>
            <br/>

            <h2 style="font-weight: bold;">Java DataBase Connectivity (JDBC) with Servlets</h2>
            <ul style="padding-left: 30px;">
                <li>JDBC code for select SQL statement</li>
                <li>JDBC code for insert SQL statement</li>
                <li>JDBC code for delete SQL statement</li>
            </ul>`,
        price: "6200",
        duration: "120 Hours",
        level: "Intermediate",
      },
      {
        url: "klic-mobile-app-development",
        name: "KLiC Mobile App Development",
        icon: "../assets/courses/mobile-dev.svg",
        content: `This course covers various components of an Android application, event handling in Android, basics of Java, graphics and multimedia support in Android and demonstration of various popular apps using a simple-to-understand method. After this course, you will be able to create apps of your own and even can work as a freelancer.
        <br><br>
        After completing this course, you will get to know various Career Opportunities like:
        <ul style="padding-left: 30px;">
                <li>Junior Android App Developer</li>
                <li>Senior Android App Developer</li>
                <li>Lead Android App Developer</li>
                <li>Mobile Application Developer</li>
                <li>UI Designer</li>
            </ul>`,
        certification: `<ul>
        <li>KLiC courses are recognised by Yashwantrao Chavan Maharashtra Open University (YCMOU).</li>
        <li>MKCL provides certificate to the KLiC learner after his/her successful course completion.</li>
        <li>Yashwantrao Chavan Maharashtra Open University (YCMOU) provides mark sheet to successfully passed KLiC learners (Jurisdiction: Maharashtra).</li>
        </ul>`,
        objective:
          "In this course, you'll dive into Android app development using Java and Android Studio, mastering interface design, user interaction handling, and multimedia integration. You'll learn essential data management techniques, networking, and delve into advanced topics. Through hands-on projects, you'll gain practical experience and insight into various career paths, enhancing your problem-solving abilities along the way.",
        syllabus: `<h2 style="font-weight: bold;">List of Software covered in the course:</h2>
        <ul style="padding-left: 30px;">
            <li>Android Studio (Integrated development environment (IDE))</li>
            <li>Java Development Tool Kit (JDK)</li>
        </ul>
        <br>
        <h2 style="font-weight: bold;">List of Software that needs to be installed: (for practice)</h2>
        <ul style="padding-left: 30px;">
            <li>Android Studio 4.1.1/ 4.1.2/4.1.3</li>
            <li>Java Development Tool Kit (JDK) 8</li>
        </ul>`,
        topics: `<h2 style="font-weight: bold;">Introduction to Android Development</h2>
        <ul style="padding-left: 30px;">
            <li>Overview of Android platform</li>
            <li>Understanding the Android ecosystem</li>
            <li>Setting up development environment (Android Studio, JDK)</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Fundamentals of Java for Android Development</h2>
        <ul style="padding-left: 30px;">
            <li>Basics of Java programming language</li>
            <li>Object-oriented programming concepts</li>
            <li>Data types, variables, and operators in Java</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Building User Interfaces in Android</h2>
        <ul style="padding-left: 30px;">
            <li>Layouts and views in Android</li>
            <li>UI components and widgets</li>
            <li>Design principles for Android apps</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Event Handling and User Interaction</h2>
        <ul style="padding-left: 30px;">
            <li>Handling user input (touch, gestures)</li>
            <li>Responding to events (clicks, swipes)</li>
            <li>Implementing interactive features in Android apps</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Working with Graphics and Multimedia</h2>
        <ul style="padding-left: 30px;">
            <li>Introduction to graphics in Android</li>
            <li>Drawing shapes and images</li>
            <li>Incorporating multimedia elements (audio, video) into apps</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Android App Development Workflow</h2>
        <ul style="padding-left: 30px;">
            <li>Creating new projects in Android Studio</li>
            <li>Understanding project structure and organization</li>
            <li>Building, running, and debugging Android apps</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Data Management and Storage</h2>
        <ul style="padding-left: 30px;">
            <li>Working with SQLite databases</li>
            <li>Implementing data persistence in Android</li>
            <li>Storing and retrieving data from local storage</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Introduction to Networking and Web Services</h2>
        <ul style="padding-left: 30px;">
            <li>Making network requests in Android</li>
            <li>Consuming RESTful APIs</li>
            <li>Handling network responses and data parsing</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Advanced Topics in Android Development</h2>
        <ul style="padding-left: 30px;">
            <li>Fragments and navigation patterns</li>
            <li>Working with background tasks and services</li>
            <li>Integrating third-party libraries and SDKs</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Project-Based Learning and App Development</h2>
        <ul style="padding-left: 30px;">
            <li>Guided projects to reinforce learning</li>
            <li>Developing real-world Android applications</li>
            <li>Best practices for app deployment and distribution</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Career Opportunities and Freelancing</h2>
        <ul style="padding-left: 30px;">
            <li>Exploring different career paths in Android development</li>
            <li>Building a portfolio and showcasing projects</li>
            <li>Strategies for freelancing and working independently</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Hands-on Practice and Troubleshooting</h2>
        <ul style="padding-left: 30px;">
            <li>Practical exercises and coding challenges</li>
            <li>Debugging common errors and issues</li>
            <li>Tips for efficient problem-solving in Android development</li>
        </ul>`,
        price: "6200",
        duration: "120 Hours",
        level: "Intermediate",
      },
      {
        url: "klic-python",
        name: "KLiC Python",
        icon: "../assets/courses/python.svg",
        content: `This course provides not only an introduction to Python programming but also gives deep knowledge of Data science, AI/ML along with web frameworks like Flask and Django
        <br><br>
        The goal of this course is to enable learners to promptly start coding by providing an introduction to the many tools, packages, modules, and libraries available for writing and running various Python codes. In this course, you'll get practical programming expertise and get ready for a rewarding future as a Python Programmer.`,
        certification: `<ul>
        <li>KLiC courses are recognised by Yashwantrao Chavan Maharashtra Open University (YCMOU).</li>
        <li>MKCL provides certificate to the KLiC learner after his/her successful course completion.</li>
        <li>Yashwantrao Chavan Maharashtra Open University (YCMOU) provides mark sheet to successfully passed KLiC learners (Jurisdiction: Maharashtra).</li>
        </ul>`,
        objective:
          "The course aims to provide participants with a comprehensive understanding of Python programming, covering essential concepts such as data types, flow control, object-oriented programming, and advanced topics including multi-threading and web development. By the end of the course, participants will be proficient in Python programming, equipped with the skills necessary to develop applications, handle data effectively, and solve real-world problems efficiently.",
        syllabus:
          "Python Introduction, Memory Management, Installation and basics of Python, Data types, Operators, Flow control statements, Python Strings, List, Tuple, Set, Dictionaries, Python functions, Advance functions, Module, packages and library, Date Time module, File handling, Exception Handling, Python loggers, JSON, Regular expression, Multi Threading, Object Oriented Programming, Inheritance, Polymorphism, Encapsulation and Abstraction, Python programming with MySQL, Python MySQL, Python GUI -tkinter, Python collections module, Python Mini Projects, Web Development using Python, Advanced Applications of Python.",
        topics: `<h2 style="font-weight: bold;">Python Introduction</h2>
        <ul style="padding-left: 30px;">
            <li>What is Python</li>
            <li>Python history</li>
            <li>Versions of Python</li>
            <li>Features of Python</li>
            <li>Limitations of Python</li>
            <li>Scripting language vs programming language</li>
            <li>Applications of Python</li>
            <li>Python2 vs Python 3</li>
            <li>What is Python used for?</li>
            <li>Flavours of Python</li>
            <li>Python compared to other languages</li>
            <li>Python vs Java</li>
            <li>How Python works? What is byte code and pycache?</li>
            <li>What is PVM</li>
            <li>Compiler vs interpreter</li>
            <li>Compile time vs runtime</li>
            <li>Future Scope of Python and career opportunity</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Memory Management</h2>
        <ul style="padding-left: 30px;">
            <li>What is Memory Management</li>
            <li>Three areas of Memory Management</li>
            <ul>
                <li>Memory management at Hardware level</li>
                <li>Memory management at OS level</li>
                <li>Memory management at Application level</li>
            </ul>
            <li>How important is memory management</li>
            <li>What is Memory management in Python</li>
            <li>Python memory allocation - Static memory and Dynamic memory</li>
            <li>2 parts of memory - Stack and Heap</li>
            <li>Garbage collector</li>
            <li>Reference counting in Python</li>
            <li>Transfering the garbage collector</li>
            <li>Importance of Performing Manual Garbage Collection</li>
            <li>Python Memory Management</li>
            <li>Common Ways to Reduce the Space Complexity</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Installation and basics of Python</h2>
        <ul style="padding-left: 30px;">
            <li>Python Installation on windows</li>
            <li>Check Python version on windows</li>
            <li>Verify Python is installed?</li>
            <li>Verify pip was installed?</li>
            <li>What is IDLE and editors</li>
            <li>How to run Python program using IDLE</li>
            <li>How to install visual studio</li>
            <li>Executing Python program</li>
            <li>Identifiers and rules to write identifiers</li>
            <li>Constants and variables</li>
            <li>Keywords or reserved keywords</li>
            <li>Python comments</li>
            <li>Python syntax</li>
            <li>Lines and Indentation</li>
            <li>Python user input</li>
        </ul>
        <br/>
        
        <h2 style="font-weight: bold;">Data types</h2>
        <ul style="padding-left: 30px;">
            <li>Text type</li>
            <li>Numeric types</li>
            <li>Sequence type (range vs xrange)</li>
            <li>Mapping types</li>
            <li>Boolean types</li>
            <li>Binary types</li>
            <li>None type</li>
            <li>Type casting</li>
        </ul>
        <br>
        <h2 style="font-weight: bold;">Operators</h2>
<ul style="padding-left: 30px;">
    <li>Arithmetic operators simulation</li>
    <li>Assignment operators simulation</li>
    <li>Comparison operators simulation</li>
    <li>Logical operators</li>
    <li>Identity operators simulation</li>
    <li>Membership operators simulation</li>
    <li>Bitwise operators simulation</li>
    <li>Precedence and Associativity of Operators</li>
    <li>Ternary operator</li>
</ul>
<br/>

<h2 style="font-weight: bold;">Flow control statements</h2>
<ul style="padding-left: 30px;">
    <li>What are control flow statements in Python?</li>
    <li>Decision control statements</li>
    <li>Python conditions and indentation</li>
    <ul>
        <li>simple if flow</li>
        <li>if else flow</li>
        <li>nested If flow</li>
        <li>if elif else flow</li>
        <li>elif ladder</li>
        <li>short hand if, if else flow</li>
    </ul>
    <li>Multiple conditions in if using and or operator</li>
    <li>Transfer statements</li>
    <ul>
        <li>Break</li>
        <li>Continue</li>
        <li>Pass</li>
    </ul>
    <li>Iterative statement</li>
    <ul>
        <li>For</li>
        <li>While</li>
    </ul>
    <li>Pattern programs</li>
</ul>
<br/>

<h2 style="font-weight: bold;">Python Strings</h2>
<ul style="padding-left: 30px;">
    <li>Data types in brief</li>
    <li>How to define string</li>
    <li>How to access string and indexing</li>
    <li>String slicing</li>
    <li>Mutable and immutable</li>
    <li>Mathematical Operators for string (+,*)</li>
    <li>Comparison of string</li>
    <li>String membership</li>
    <li>Format string</li>
    <li>Escape character</li>
    <li>Removing spaces from string</li>
    <li>Finding substring</li>
    <li>Counting substring and len()</li>
    <li>Replacing a string</li>
    <li>Splitting and Joining of string</li>
    <li>Changing case of a string</li>
    <li>Checking starting and ending part of the string</li>
    <li>Methods to check type of characters present in string</li>
    <li>Revision string methods</li>
</ul>
<br/>

<h2 style="font-weight: bold;">List</h2>
<ul style="padding-left: 30px;">
    <li>What is list and its creation</li>
    <li>Accessing elements of list</li>
    <li>Mutability and immutability</li>
    <li>List Traversing</li>
    <li>Functions of list - get information about list</li>
    <li>Manipulating list</li>
    <li>Ordering elements of list</li>
    <li>Iterative statement</li>
    <li>Use of mathematical operators for list</li>
    <li>Comparison and membership operators</li>
    <li>Nested list</li>
    <li>List comprehension</li>
</ul>
<br/>
<h2 style="font-weight: bold;">Tuple</h2>
<ul style="padding-left: 30px;">
    <li>What is tuple and how to create tuple</li>
    <li>Accessing tuple</li>
    <li>Mathematical Operators for tuple</li>
    <li>Functions of tuple</li>
    <li>Tuple Packing unpacking</li>
    <li>Tuple comprehension</li>
    <li>Difference between list and tuple</li>
</ul>
<br/>

<h2 style="font-weight: bold;">Set</h2>
<ul style="padding-left: 30px;">
    <li>SET and its creation</li>
    <li>Important functions of set</li>
    <li>Mathematical Operations on set</li>
    <li>Membership operators</li>
    <li>Set comprehension</li>
    <li>Set won't support slicing and indexing</li>
</ul>
<br/>

<h2 style="font-weight: bold;">Dictionaries</h2>
<ul style="padding-left: 30px;">
    <li>Dictionary and its creation</li>
    <li>Accessing dict data</li>
    <li>Updating and deleting dict</li>
    <li>Loop dictionaries</li>
    <li>Important functions of dict</li>
    <li>Dict comprehension and nested dictionaries</li>
</ul>
<br/>

<h2 style="font-weight: bold;">Python functions</h2>
<ul style="padding-left: 30px;">
    <li>Functions and its types</li>
    <li>Parameters</li>
    <li>Return statement</li>
    <li>Types of arguments part 1</li>
    <li>Types of arguments part 2</li>
    <li>Types of variable</li>
    <li>Namespaces and Global keyword</li>
    <li>Recursive function</li>
    <li>Nested function</li>
</ul>
<br/>
<h2 style="font-weight: bold;">Advance functions</h2>
<ul style="padding-left: 30px;">
    <li>What is anonymous function</li>
    <li>Difference between normal function and anonymous function</li>
    <li>Lambda function in detail</li>
    <li>Filter, map and reduce functions</li>
    <li>Decorators</li>
    <li>Calling decorators</li>
    <li>Decorator chaining</li>
    <li>Generators</li>
    <li>Advantages of Generators</li>
    <li>Generators vs Normal collections wrt performance</li>
    <li>Iterators</li>
</ul>
<br/>
<h2 style="font-weight: bold;">Module, Packages, and Library</h2>
<ul style="padding-left: 30px;">
    <li>What is Module</li>
    <li>Module aliasing</li>
    <li>Various possibilities of import</li>
    <li>Reloading a module</li>
    <li>Finding members of a module by using dir()</li>
    <li>The special variable name</li>
    <li>What is package</li>
    <li>__init__.py</li>
    <li>What is library</li>
    <li>Random module</li>
    <li>Math module</li>
    <li>Python Imaging Library (PIL)</li>
    <li>MoviePy</li>
    <li>pyscreenshot</li>
</ul>
<br/>

<h2 style="font-weight: bold;">Date Time module</h2>
<ul style="padding-left: 30px;">
    <li>What is Date Time module and its classes</li>
    <li>Date class and its attributes</li>
    <li>Date class methods</li>
    <li>Time class and its attributes</li>
    <li>Time class and its methods</li>
    <li>DateTime class and its attributes</li>
    <li>DateTime class and its methods</li>
    <li>Timedelta class and its attributes</li>
    <li>Timedelta class and its methods and operations supported</li>
    <li>Tzinfo and timezone class</li>
</ul>
<br/>

<h2 style="font-weight: bold;">File Handling</h2>
<ul style="padding-left: 30px;">
    <li>File handling basics and types of files</li>
    <li>Opening a file with different modes and closing file</li>
    <li>Various properties of file object</li>
    <li>Writing data and reading data from text file</li>
    <li>The with statement, seek(), tell()</li>
    <li>OS module and working with directories</li>
    <li>OS module functions</li>
    <li>Running other programs from python program</li>
    <li>Handling Binary data</li>
    <li>Handling CSV file</li>
    <li>Zipping unzipping files</li>
</ul>
<br/>

<h2 style="font-weight: bold;">Exception Handling</h2>
<ul style="padding-left: 30px;">
    <li>Types of errors</li>
    <li>What is Exception</li>
    <li>Python's Exception handling hierarchy</li>
    <li>Customized try-except - with try-except, without try-except</li>
    <li>Control flow in try-except</li>
    <li>Printing exception information</li>
    <li>Try with multiple except blocks</li>
    <li>Single except block can handle multiple exceptions</li>
    <li>Default except block</li>
    <li>Finally block</li>
    <li>Control flow try-except and finally</li>
    <li>Nested try-except finally</li>
    <li>Else with try-except finally</li>
    <li>All possible combinations of try-except finally</li>
    <li>Types of exceptions</li>
</ul>
<br/>
<h2 style="font-weight: bold;">Python Loggers</h2>
<ul style="padding-left: 30px;">
    <li>What is log and log file in programming</li>
    <li>Logging the exception</li>
    <li>Logging levels</li>
    <li>BasicConfig and formatting</li>
    <li>Python logging getlogger</li>
    <li>File handler working with file handlers</li>
    <li>Different logger object</li>
    <li>Classes and functions</li>
    <li>Capturing stack traces</li>
</ul>
<br/>
<h2 style="font-weight: bold;">JSON</h2>
<ul style="padding-left: 30px;">
    <li>What is JSON</li>
    <li>JSON to Python and Python to JSON</li>
    <li>Json dumps</li>
    <li>Json loads</li>
    <li>Serializing deserializing</li>
    <li>Pickling unpickling</li>
    <li>Need of pickling and unpickling</li>
</ul>
<br/>
<h2 style="font-weight: bold;">Regular Expression</h2>
<ul style="padding-left: 30px;">
    <li>What is Reg ex</li>
    <li>Character classes</li>
    <li>Quantifiers</li>
    <li>Important functions of re module</li>
    <li>Symbols</li>
    <li>Web scraping using reg exp</li>
    <li>Programs related to reg exp</li>
</ul>
<br/>
<h2 style="font-weight: bold;">Multi-Threading</h2>
<ul style="padding-left: 30px;">
    <li>Multi-Threading and its types</li>
    <li>Ways of creating thread in Python</li>
    <li>Difference in program with - without multi threading</li>
    <li>Thread identification number(ident)</li>
    <li>Function/methods on multithreading</li>
    <li>Daemon Threads</li>
    <li>Synchronization</li>
    <li>Difference between lock and semaphore</li>
    <li>Inter-thread communication</li>
</ul>
<br/>
<h2 style="font-weight: bold;">Object-Oriented Programming</h2>
<ul style="padding-left: 30px;">
    <li>What is class</li>
    <li>How to define class</li>
    <li>What is object</li>
    <li>Reference variable</li>
    <li>Self</li>
    <li>Constructor</li>
    <li>Difference between constructor and methods</li>
    <li>Types of variables - instance variable, static variable, local variable</li>
    <li>Types of methods - instance method, setter getter method, class method, static method</li>
    <li>Inner class</li>
    <li>Garbage collectors</li>
    <li>Garbage collection methods</li>
    <li>Destructor</li>
</ul>
<br/>
<h2 style="font-weight: bold;">Inheritance</h2>
<ul style="padding-left: 30px;">
    <li>What is inheritance</li>
    <li>Super class sub class</li>
    <li>Benefits of inheritance</li>
    <li>Creating child class</li>
    <li>Types of inheritance - single inheritance, multilevel inheritance, multiple inheritance, hierarchical inheritance, hybrid inheritance</li>
</ul>
<br/>

<h2 style="font-weight: bold;">Polymorphism</h2>
<ul style="padding-left: 30px;">
    <li>What is Polymorphism</li>
    <li>How to use Polymorphism</li>
    <li>Duck typing (philosophy)</li>
    <li>Strong typing and hasattr() function</li>
    <li>Overloading and its types</li>
    <li>Operator overloading</li>
    <li>Method Overloading</li>
    <li>Constructor overloading</li>
    <li>Method overriding</li>
    <li>Constructor overriding</li>
</ul>
<br/>

<h2 style="font-weight: bold;">Encapsulation and Abstraction</h2>
<ul style="padding-left: 30px;">
    <li>What is encapsulation</li>
    <li>Why we need encapsulation</li>
    <li>How to achieve encapsulation in Python</li>
    <li>Access modifiers in encapsulation</li>
    <li>Private members</li>
    <li>Public method to access private members</li>
    <li>Name Mangling to access private members</li>
    <li>Protected members</li>
    <li>Public members</li>
    <li>Advantages of encapsulation</li>
    <li>Data Abstraction in Python</li>
    <li>Why abstraction is important</li>
    <li>Abstraction classes in Python</li>
    <li>Abstract base class ABC and its working</li>
</ul>
<br/>

<h2 style="font-weight: bold;">Python programming with MySQL</h2>
<ul style="padding-left: 30px;">
    <li>What is database</li>
    <li>Python supports various databases</li>
    <li>Why we are using MySQL</li>
    <li>MySQL driver installation</li>
    <li>Learn to write connector</li>
    <li>Creating connection</li>
    <li>Check connection</li>
    <li>Close connection</li>
    <li>Create database</li>
    <li>Create table</li>
    <li>Primary key</li>
    <li>Foreign key</li>
    <li>CRUD operation</li>
    <li>Close connection</li>
</ul>
<br/>

<h2 style="font-weight: bold;">Python MySQL</h2>
<ul style="padding-left: 30px;">
    <li>MySQL INSERT</li>
    <li>Cursor Methods and Execute methods</li>
    <li>MySQL select</li>
    <li>Fetch methods, row properties rowcount,id</li>
    <li>MySQL WHERE</li>
    <li>Wildcard characters</li>
    <li>MySQL ORDER BY</li>
    <li>MySQL DELETE</li>
    <li>Commit and rollback</li>
    <li>MySQL drop table</li>
    <li>MySQL UPDATE</li>
    <li>MySQL LIMIT</li>
    <li>MySQL JOIN</li>
    <li>Parameterized query, Tuple parameter,dictionary parameter</li>
</ul>
<br/>

<h2 style="font-weight: bold;">Python GUI - tkinter</h2>
<ul style="padding-left: 30px;">
    <li>What is GUI programming?</li>
    <li>Methods while creating the Python application with GUI - TK, mainloop</li>
    <li>Widgets in tkinter application - Button, Canvas, Checkbutton, Entry, Frame, Label, Listbox, Menubutton, Menu, Message, Radio button, Scale, Scrollbar, Text, Toplevel, Spinbox, Pannedwindow</li>
</ul>
<br/>
<h2 style="font-weight: bold;">Python collections module</h2>
<ul style="padding-left: 30px;">
    <li>What is the purpose of collection module</li>
    <li>Counters</li>
    <li>Ordered dict</li>
    <li>Default dict</li>
    <li>Chainmap</li>
    <li>Namedtuple</li>
    <li>DeQue</li>
    <li>UserDict</li>
    <li>UserList</li>
    <li>UserString</li>
</ul>
<br/>

<h2 style="font-weight: bold;">Python Mini Projects</h2>
<ul style="padding-left: 30px;">
    <li>Python fundamental Programs</li>
    <li>Calculator</li>
    <li>Password Generator</li>
    <li>Tic Tac Toe</li>
    <li>Rock Paper Scissors</li>
    <li>Chat Bot</li>
    <li>BMI Calculator</li>
    <li>Story Generator</li>
    <li>Quizz</li>
    <li>Create Acronyms</li>
</ul>
<br/>

<h2 style="font-weight: bold;">Web Development using Python</h2>
<ul style="padding-left: 30px;">
    <li>Introduction to Django</li>
    <li>Django Project and App life cycle</li>
    <li>Creating Project and App</li>
    <li>Django Templates and Static</li>
    <li>Django Models</li>
    <li>Django Forms</li>
    <li>Django Views</li>
    <li>Django Sessions and Cookies</li>
    <li>Django Serialization,Deserialization and Mixins</li>
    <li>Django Authentication and Authorization</li>
    <li>Django Middleware</li>
    <li>Send Email in Django and CSV,PDF files and GIT & Github</li>
    <li>Django Rest Framework Introduction</li>
    <li>Django Rest Framework Serializer and Deserializer</li>
    <li>Django Rest Framework Views</li>
    <li>Django Rest Framework Authentication And Authorization</li>
    <li>Django Rest Framework Pagination and Routers</li>
</ul>
<br/>

<h2 style="font-weight: bold;">Advanced Applications of Python</h2>
<ul style="padding-left: 30px;">
    <li>Learn basics of Artificial Intelligence with Python</li>
    <li>Learn basics of Machine Learning with Python</li>
    <li>Learn Data Science basics with Python</li>
</ul>`,
        price: "6200",
        duration: "120 Hours",
        level: "Intermediate",
      },
    ],
  },
  {
    category: "Basic Computing",
    url: "basic-computing",
    categoryIcon: "../assets/categories/basic.png",
    courses: [
      {
        url: "computer-basics",
        name: "Computer Basics",
        icon: "../assets/courses/basic.svg",
        content: `In today's digital age, proficiency in computing is essential for navigating both personal and professional realms. The Basic Computing course is designed to provide participants with a solid foundation in computer literacy, empowering them to confidently interact with technology. Whether you're a beginner or seeking to refresh your skills, this course covers fundamental concepts in an accessible and engaging manner. From understanding computer hardware and software to mastering essential applications and online safety practices, participants will emerge equipped with the knowledge and skills needed to thrive in the digital world.`,
        objective:
          "The Basic Computing course aims to provide participants with essential skills and knowledge to navigate the digital landscape confidently. Through hands-on learning, participants will master computer fundamentals, software applications, and online safety practices, empowering them to excel in both personal and professional endeavors",
        syllabus:
          "Introduction to Computers, Understanding Hardware and Software, Operating Systems, Introduction to Windows OS, File Management, Software Applications, Email Basics, Internet Basics, Online Safety and Security, Introduction to Cloud Computing",
        topics: `<h2 style="font-weight: bold;">Basic Computing</h2>
        <ul style="padding-left: 30px;">
            <li>Introduction to Computers</li>
            <li>Understanding Hardware and Software</li>
            <li>Operating Systems</li>
            <li>Introduction to Windows OS</li>
            <li>File Management</li>
            <li>Introduction to File Systems</li>
            <li>Using File Explorer/Finder</li>
            <li>Creating, Moving, Renaming, and Deleting Files and Folders</li>
            <li>Software Applications</li>
            <li>Word Processing with Microsoft Word/LibreOffice Writer</li>
            <li>Creating and Formatting Documents</li>
            <li>Working with Tables and Graphics</li>
            <li>Spreadsheets with Microsoft Excel/LibreOffice Calc</li>
            <li>Creating and Formatting Spreadsheets</li>
            <li>Using Formulas and Functions</li>
            <li>Creating Charts and Graphs</li>
            <li>Presentations with Microsoft PowerPoint/LibreOffice Impress</li>
            <li>Creating Slides and Adding Content</li>
            <li>Applying Themes and Transitions</li>
            <li>Email Basics</li>
            <li>Introduction to Email Communication</li>
            <li>Setting up and Managing Email Accounts</li>
            <li>Composing and Sending Emails</li>
            <li>Receiving and Managing Emails</li>
            <li>Internet Basics</li>
            <li>Introduction to the Internet</li>
            <li>Using Web Browsers</li>
            <li>Searching the Web</li>
            <li>Understanding URLs and Hyperlinks</li>
            <li>Online Safety and Security</li>
            <li>Understanding Cyber Threats</li>
            <li>Protecting Personal Information Online</li>
            <li>Safe Browsing Practices</li>
            <li>Introduction to Cloud Computing</li>
            <li>Understanding Cloud Services</li>
            <li>Cloud Storage and File Sharing</li>
            <li>Collaboration Tools</li>
            <li>Introduction to Google Drive and Microsoft OneDrive</li>
            <li>Creating and Sharing Documents and Folders</li>
        </ul>`,
        price: "5000",
        duration: "120 Hours",
        level: "Beginner",
      },
    ],
  },
];
